import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';


const SchemaLocalBusinessForm: React.FC<any> = (props: any) => {

    const { form } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@id"}
                        name='@id'
                        required={false}
                        defaultValue={form.defaults['@id']}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name='name'
                        required={false}
                        defaultValue={form.defaults.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Alternate Name"}
                        name='alternatename'
                        required={false}
                        defaultValue={form.defaults.alternatename}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"URL"}
                        name='url'
                        required={false}
                        defaultValue={form.defaults.url}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Main Entity Of Page"}
                        name='mainEntityOfPage'
                        required={false}
                        defaultValue={form.defaults.mainEntityOfPage}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Image"}
                        name='image'
                        required={false}
                        defaultValue={form.defaults.image}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Price Range"}
                        name='priceRange'
                        required={false}
                        defaultValue={form.defaults.priceRange}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Has Map"}
                        name='hasMap'
                        required={false}
                        defaultValue={form.defaults.hasMap}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Email"}
                        name='email'
                        required={false}
                        defaultValue={form.defaults.email}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Telephone"}
                        name='telephone'
                        required={false}
                        defaultValue={form.defaults.telephone}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldTextArea
                        form={form}
                        label={"Description"}
                        name='description'
                        required={false}
                        defaultValue={form.defaults.description}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldTextArea
                        form={form}
                        label={"Disambiguating Description"}
                        name='disambiguatingdescription'
                        required={false}
                        defaultValue={form.defaults.disambiguatingdescription}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Same As'}
            />

            <FieldRepeater
                form={form}
                name={'sameAs'}
                addButtonLabel={"Add Same As"}
            >

                {form.values.sameAs.map((row, n) => (

                    <React.Fragment key={form.key + "-" + n}>

                        <FieldText
                            form={form}
                            label={"Same As"}
                            name={`sameAs[${n}]`}
                            required={false}
                            defaultValue={typeof row === 'object' ? '' : row}
                        />


                    </React.Fragment>

                ))}

            </FieldRepeater>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Additional Type'}
            />

            <FieldRepeater
                form={form}
                name={'additionalType'}
                addButtonLabel={"Add Additional Type"}
            >

                {form.values.additionalType.map((row, n) => (

                    <React.Fragment key={form.key + "-" + n}>

                        <FieldText
                            form={form}
                            label={"Additional Type"}
                            name={`additionalType[${n}]`}
                            required={false}
                            defaultValue={typeof row === 'object' ? '' : row}
                        />


                    </React.Fragment>

                ))}

            </FieldRepeater>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Area Served'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'areaServed'}
                        addButtonLabel={"Add Area Served"}
                    >

                        {form.values.areaServed.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <Row gutter={8}>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Type"}
                                            name={`areaServed[${n}].type`}
                                            required={false}
                                            defaultValue={row.type}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Name"}
                                            name={`areaServed[${n}].name`}
                                            required={false}
                                            defaultValue={row.name}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"URL"}
                                            name={`areaServed[${n}].url`}
                                            required={false}
                                            defaultValue={row.url}
                                        />

                                    </Col>

                                </Row>

                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Aggregate Rating'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Type"}
                        name={`aggregateRating.type`}
                        required={false}
                        defaultValue={form.defaults.aggregateRating.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Rating Value"}
                        name={`aggregateRating.ratingValue`}
                        required={false}
                        defaultValue={form.defaults.aggregateRating.ratingValue}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Review Count"}
                        name={`aggregateRating.reviewCount`}
                        required={false}
                        defaultValue={form.defaults.aggregateRating.reviewCount}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Address'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Type"}
                        name={`address.type`}
                        required={false}
                        defaultValue={form.defaults.address.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Address Region"}
                        name={`address.addressRegion`}
                        required={false}
                        defaultValue={form.defaults.address.addressRegion}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Postal Code"}
                        name={`address.postalCode`}
                        required={false}
                        defaultValue={form.defaults.address.postalCode}
                    />

                </Col>
                
                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Address Locality"}
                        name={`address.addressLocality`}
                        required={false}
                        defaultValue={form.defaults.address.addressLocality}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Street Address"}
                        name={`address.streetAddress`}
                        required={false}
                        defaultValue={form.defaults.address.streetAddress}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'GEO'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Type"}
                        name={`geo.type`}
                        required={false}
                        defaultValue={form.defaults.geo.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Latitude"}
                        name={`geo.latitude`}
                        required={false}
                        defaultValue={form.defaults.geo.latitude}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Longitude"}
                        name={`geo.longitude`}
                        required={false}
                        defaultValue={form.defaults.geo.longitude}
                    />

                </Col>

            </Row>

        </>

    ), [props]);

}

export default SchemaLocalBusinessForm;
