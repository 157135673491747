import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import SchemaServiceServiceOutput from './Sections/SchemaServiceServiceOutput';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';


const SchemaServiceForm: React.FC<any> = (props: any) => {

    const { form } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@id"}
                        name='serviceId'
                        required={false}
                        defaultValue={form.defaults.serviceId}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name='name'
                        required={false}
                        defaultValue={form.defaults.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Service Type"}
                        name='serviceType'
                        required={false}
                        defaultValue={form.defaults.serviceType}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Audience"}
                        name='audience'
                        required={false}
                        defaultValue={form.defaults.audience}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Description"}
                        name='description'
                        required={false}
                        defaultValue={form.defaults.description}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"URL"}
                        name='url'
                        required={false}
                        defaultValue={form.defaults.url}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRelationship
                        form={form}
                        label={"Main Entity"}
                        name={`entityId`}
                        required={true}
                        preload={true}
                        endpoint={'MainEntities'}
                        defaultValue={form.defaults.entityId}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Area Served'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'areaServed'}
                        addButtonLabel={"Add Area Served"}
                    >

                        {form.values.areaServed.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldText
                                            form={form}
                                            label={"Type"}
                                            name={`areaServed[${n}].type`}
                                            required={false}
                                            defaultValue={row.type}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldText
                                            form={form}
                                            label={"Name"}
                                            name={`areaServed[${n}].name`}
                                            required={false}
                                            defaultValue={row.name}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldText
                                            form={form}
                                            label={"@id"}
                                            name={`areaServed[${n}].id`}
                                            required={false}
                                            defaultValue={row.id}
                                        />

                                    </Col>

                                </Row>

                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Provider'}
            />

            <Row gutter={8} edge={true}>

                {/* <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@type"}
                        name={`provider.type`}
                        required={false}
                        defaultValue={form.defaults.provider.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@id"}
                        name={`provider.id`}
                        required={false}
                        defaultValue={form.defaults.provider.id}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name={`provider.name`}
                        required={false}
                        defaultValue={form.defaults.provider.name}
                    />

                </Col> */}

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Alternate Name"}
                        name={`provider.alternatename`}
                        required={false}
                        defaultValue={form.defaults.provider.alternatename}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Description"}
                        name={`provider.description`}
                        required={false}
                        defaultValue={form.defaults.provider.description}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Disambiguating Description"}
                        name={`provider.disambiguatingdescription`}
                        required={false}
                        defaultValue={form.defaults.provider.disambiguatingdescription}
                    />

                </Col>

                {/* <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Main Entity Of Page"}
                        name={`provider.mainEntityOfPage`}
                        required={false}
                        defaultValue={form.defaults.provider.mainEntityOfPage}
                    />

                </Col> */}

                {/* <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'provider.additionalType'}
                        addButtonLabel={"Add Additional Type"}
                    >

                        {form.values.provider.additionalType.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"Type"}
                                            name={`provider.additionalType[${n}]`}
                                            required={false}
                                            defaultValue={typeof row === 'object' ? '' : row}
                                        />

                                    </Col>

                                </Row>

                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col> */}

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Available Channel'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"@id"}
                        name={`availableChannel.id`}
                        required={false}
                        defaultValue={form.defaults.availableChannel.id}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"@type"}
                        name={`availableChannel.type`}
                        required={false}
                        defaultValue={form.defaults.availableChannel.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name={`availableChannel.name`}
                        required={false}
                        defaultValue={form.defaults.availableChannel.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Service URL"}
                        name={`availableChannel.serviceUrl`}
                        required={false}
                        defaultValue={form.defaults.availableChannel.serviceUrl}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Service Location"}
                        name={`availableChannel.serviceLocation`}
                        required={false}
                        defaultValue={form.defaults.availableChannel.serviceLocation}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Description"}
                        name={`availableChannel.description`}
                        required={false}
                        defaultValue={form.defaults.availableChannel.description}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <Seperator
                        top={'auto'}
                        bottom={'auto'}
                        heading={'Available Channel - Service Phone'}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"@id"}
                                name={`availableChannel.servicePhone.id`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.id}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"@type"}
                                name={`availableChannel.servicePhone.type`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.type}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"Telephone"}
                                name={`availableChannel.servicePhone.telephone`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.telephone}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"Name"}
                                name={`availableChannel.servicePhone.name`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.name}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldText
                                form={form}
                                label={"Contact Type"}
                                name={`availableChannel.servicePhone.contactType`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.contactType}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldText
                                form={form}
                                label={"Available Language"}
                                name={`availableChannel.servicePhone.availableLanguage`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.availableLanguage}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldText
                                form={form}
                                label={"Description"}
                                name={`availableChannel.servicePhone.description`}
                                required={false}
                                defaultValue={form.defaults.availableChannel.servicePhone.description}
                            />

                        </Col>

                    </Row>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Image'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'image'}
                        addButtonLabel={"Add Image"}
                    >

                        {form.values.image.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldSelect
                                            form={form}
                                            label={"Type"}
                                            name={`image[${n}].type`}
                                            required={false}
                                            defaultValue={row.type}
                                            options={[
                                                { label: 'Image Object', value: 'ImageObject' },
                                                { label: 'String', value: 'string' }
                                            ]}
                                        />

                                    </Col>

                                    {form.values.image[n].type === 'string' &&

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Type"}
                                                name={`image[${n}].value`}
                                                required={false}
                                                defaultValue={row.value}
                                            />

                                        </Col>

                                    }

                                    {form.values.image[n].type === 'ImageObject' &&

                                        <>

                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"@id"}
                                                    name={`image[${n}].id`}
                                                    required={false}
                                                    defaultValue={row.id}
                                                />

                                            </Col>

                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"Width"}
                                                    name={`image[${n}].width`}
                                                    required={false}
                                                    defaultValue={row.width}
                                                />

                                            </Col>

                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"Height"}
                                                    name={`image[${n}].height`}
                                                    required={false}
                                                    defaultValue={row.height}
                                                />

                                            </Col>

                                            <Col col={{ xs: 24, md: 24 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"URL"}
                                                    name={`image[${n}].url`}
                                                    required={false}
                                                    defaultValue={row.url}
                                                />

                                            </Col>

                                        </>

                                    }

                                </Row>

                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <SchemaServiceServiceOutput form={form} />

        </>


    ), [props]);

}

export default SchemaServiceForm;
