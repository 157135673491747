import { Panel } from '@dex/bubl-dash';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useEffect, useMemo } from 'react';
import KeywordRankingSingleViewKeyword from '../../KeywordRanking/Components/KeywordRankingSingleViewKeyword';
import KeywordRankingSingleViewRankings from '../../KeywordRanking/Components/KeywordRankingSingleViewRakings';
import KeywordRankingSingleViewSummary from '../../KeywordRanking/Components/KeywordRankingSingleViewSummary';
import DashboardApi from '../DashboardApi';

import './Dashboard.module.scss';

const DashboardClientSingleLocation: React.FC<any> = (props: any) => {

    const { client, locationId, passCode } = props;

    const location = DashboardApi.clientLocation(client.id, locationId);

    useEffect(() => {

        location.run({
            params: { passCode: passCode }
        });

    }, []);

    return useMemo(() => (

        <>
            {location.data &&
                <>

                    <Panel
                        heading={location.data.name}
                    >

                        <KeywordRankingSingleViewSummary
                            data={location.data}
                        />


                        <Space />

                        <KeywordRankingSingleViewRankings
                            data={location.data}
                        />


                    </Panel>

                </>
            }
        </>

    ), [location.data]);

}

export default DashboardClientSingleLocation;
