import FieldWrap from '@dex/bubl-dash/src/lib/components/Fields/FieldWrap';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import React, { useMemo } from 'react';
import CSVReader from 'react-csv-reader';

import './FieldCsv.module.scss';

const FieldCsv: React.FC<any> = (props: props) => {

    const app = useApp();

    const { form, name, parserOptions, maxFileSize } = props;

    if (form) form.addField(name, props)

    const onFileLoad = (value, fileInfo) => {

        const { size } = fileInfo;

        if (!!size && size >= (parseInt(maxFileSize) * 1024 * 1024)) {

            app.alert(`Maximum allowed ${maxFileSize}mb`, 'error');

            return;

        }

        if (form) form.handleChange({ name, value });

        if (props.onFileLoaded) props.onFileLoaded(value);

    }

    return useMemo(() => {

        return (

            <FieldWrap {...props}>

                <div styleName={'csv-wrapper'}>

                    <CSVReader
                        parserOptions={parserOptions}
                        onFileLoaded={onFileLoad}
                    />

                    {!!maxFileSize && <span>Maximum file size of {maxFileSize}mb</span>}

                </div>

            </FieldWrap>

        );

    }, [props]);

}

interface props {
    name: string;
    onFileLoaded?: (values: any) => any;
    parserOptions?: object;
    [key: string]: any;
}

export default FieldCsv;
