import React, { useEffect, useMemo } from 'react';

const SchemaLocalBusinessView: React.FC<any> = (props: any) => {

    const { data } = props;

    const { areaServed, additionalType, aggregateRating, address, geo, sameAs } = data;

    const schema = {
        "@context": "https://schema.org",
        "@type": data.type,
        "@id": data['@id'],
        additionalType: additionalType,
        url: data.url,
        sameAs: sameAs,
        name: data.name,
        alternateName: data.alternatename,
        disambiguatingDescription: data.disambiguatingdescription,
        mainEntityOfPage: data.mainEntityOfPage,
        areaServed: areaServed.map( item => ({
            '@type': item.type,
            'name': item.name,
            'url': item.url
        })),
        description: data.description,
        image: data.image,
        priceRange: data.priceRange,
        hasMap: data.hasMap,
        email: data.email,
        telephone: data.telephone,
        aggregateRating: {
            '@type': aggregateRating.type,
            ratingValue: aggregateRating.ratingValue,
            reviewCount: aggregateRating.reviewCount,
        },
        address: {
            '@type': address.type,
            addressLocality: address.addressLocality,
            addressRegion: address.addressRegion,
            postalCode: address.postalCode,
            streetAddress: address.streetAddress,
        },
        geo: {
            '@type': geo.type,
            latitude: geo.latitude,
            longitude: geo.longitude,
        },
    }

    const string = '<script type="application/ld+json">\n' + JSON.stringify(schema, null, 2) + '\n</script>';

    useEffect(() => {

        props.onLoad(string);

    }, [string, data])

    return useMemo(() => (

        <pre style={{ whiteSpace: 'pre-wrap' }}>{string}</pre>

    ), [data]);

}

export default SchemaLocalBusinessView;
