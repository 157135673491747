import React, { useEffect, useMemo } from 'react';

const SchemaServiceView: React.FC<any> = (props: any) => {

    const { data } = props;

    console.log(data)

    const { areaServed, provider, availableChannel, image, serviceOutput } = data;

    const { mainEntity, mainEntityOfPage, author, contentLocation, publisher, sameAs } = data.entity;

    const schema = {
        "@context": "https://schema.org",
        "@type": "Service",
        serviceType: data.serviceType,
        areaServed: areaServed.map((item) => ({
            "@type": item.type,
            name: item.name,
            "@id": item.id
        })),
        audience: data.audience,
        provider: {
            "@type": data.entity.type,
            name: provider.name,
            "@id": data.entity['@id'],
            additionalType: mainEntity.additionalType,
            description: provider.description,
            alternatename: provider.alternatename,
            disambiguatingdescription: provider.disambiguatingdescription,
            mainEntityOfPage: mainEntityOfPage
        },
        availableChannel: {
            "@type": availableChannel.type,
            serviceUrl: availableChannel.serviceUrl,
            servicePhone: {
                "@type": availableChannel.servicePhone.type,
                telephone: availableChannel.servicePhone.telephone,
                name: availableChannel.servicePhone.name,
                description: availableChannel.servicePhone.description,
                contactType: availableChannel.servicePhone.contactType,
                "@id": availableChannel.servicePhone.id,
                availableLanguage: availableChannel.servicePhone.availableLanguage
            },
            serviceLocation: availableChannel.serviceLocation,
            name: availableChannel.name,
            description: availableChannel.description,
            "@id": availableChannel.id
        },
        description: data.description,
        image: image.map(item => {

            if (item.type === 'string') return item.value;

            const imageObject = {
                "@type": "ImageObject",
                width: item.width,
                url: item.url,
                height: item.height,
                "@id": item.id
            }

            if (item.type === 'ImageObject') return imageObject;

            return "";

        }),
        name: data.name,
        serviceOutput: {
            "@type": serviceOutput.type,
            name: serviceOutput.name,
            description: serviceOutput.description,
            brand: serviceOutput.brand,
            "@id": serviceOutput.id,
            offers: {
                "@type": serviceOutput.offers.type,
                availability: serviceOutput.offers.availability,
                priceValidUntil: serviceOutput.offers.priceValidUntil,
                name: serviceOutput.offers.name,
                price: serviceOutput.offers.price,
                priceCurrency: serviceOutput.offers.priceCurrency,
                url: serviceOutput.offers.url
            },
            aggregateRating: {
                "@type": serviceOutput.aggregateRating.type,
                ratingValue: serviceOutput.aggregateRating.ratingValue,
                reviewCount: serviceOutput.aggregateRating.reviewCount
            },
            review: {
                "@type": serviceOutput.review.type,
                mainEntity: serviceOutput.review.mainEntity,
                provider: serviceOutput.review.provider,
                author: {
                    "@type": serviceOutput.review.author.type,
                    name: serviceOutput.review.author.name
                },
                datePublished: serviceOutput.review.datePublished,
                reviewRating: {
                    "@type": serviceOutput.review.reviewRating.type,
                    bestRating: serviceOutput.review.reviewRating.bestRating,
                    ratingValue: serviceOutput.review.reviewRating.ratingValue
                },
                reviewBody: serviceOutput.review.reviewBody
            }
        },
        url: data.url,
        "@id": data.serviceId
    }

    const string = '<script type="application/ld+json">\n' + JSON.stringify(schema, null, 2) + '\n</script>';

    useEffect(() => {

        props.onLoad(string);

    }, [string, data])

    return useMemo(() => (

        <pre style={{ whiteSpace: 'pre-wrap' }}>{string}</pre>

    ), [data]);

}

export default SchemaServiceView;
