import ReportsIndex from './ReportsIndex';
import ReportsCreate from './ReportsCreate';
import ReportsSettings from './ReportsSettings';
import ReportsSingle from './ReportsSingle';

export default function (app) {

    const settings = ReportsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        // modelName: settings.modelName,
        // endpoint: settings.endpoint,
        // idKey: settings.idKey,
        // primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "Syndication Report",
                path: settings.path,
            },
        ],
    });

    ReportsIndex(app, settings);

}