import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

import './styles/App.scss';

//Icons

import GeIconActive from './assets/GMap-White.svg'
import GeIconInactive from './assets/GMap-Black.svg'

import SgIconActive from './assets/Schema-White.svg'
import SgIconInactive from './assets/Schema-Black.svg'

import PpIconActive from './assets/Indexer-White.svg'
import PpIconInactive from './assets/Indexer-Black.svg'

import TrackingIconActive from './assets/Tracker-White.svg'
import TrackingIconInactive from './assets/Tracker-Black.svg'

import RankIconActive from './assets/Rank-White.svg'
import RankIconInactive from './assets/Rank-Black.svg'

//Views
import Entities from './views/Entities/Entities';
import MainEntities from './views/MainEntities/MainEntities';
import Schema from './views/Schema/Schema';
import PowerPage from './views/PowerPage/PowerPage';
import Home from './views/Home/Home';
import Tracker from './views/Tracker/Tracker';
import TextToSpeech from './views/TextToSpeech/TextToSpeech';
import Reports from './views/Reports/Reports';
import KeywordRanking from './views/KeywordRanking/KeywordRanking';
import Clients from './views/Clients/Clients';
import Dashboard from './views/Dashboard/Dashboard';

const app = new BublDash({
    name: "Surchability Tools",
    //icon: "assets/logo.png",
    accessRoles: ['admin', 'developer', 'super'], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'vendor',
            label: 'Vendor'
        },
        {
            key: 'admin',
            label: 'Admin'
        },
        {
            key: 'super',
            label: 'Super'
        }
    ],
    env: window['bublENV'] || "development", //set env by modifying the output sent by express server
    endpoints: {
        development: window['bublEndpointdevelopment'] || window.location.protocol + "//" + window.location.hostname + ":3000/api",
        production: window['bublEndpointproduction'] || null,
        staging: window['bublEndpointstaging'] || null,
    },
    apiTimeout: 1000000
});

app.addRoute({
    name: "home",
    path: "/",
    sidebar: false,
    component: Home,
    componentProps: {},
    caps: [],
});

app.addZone({
    key: "rank",
    label: "Ranking",
    priority: 10,
    icon: RankIconInactive,
    iconActive: RankIconActive,
});

app.addZone({
    key: "ge",
    label: "GE",
    priority: 10,
    icon: GeIconInactive,
    iconActive: GeIconActive,
});

app.addZone({
    key: "sg",
    label: "SG",
    priority: 10,
    icon: SgIconInactive,
    iconActive: SgIconActive,
});

app.addZone({
    key: "pp",
    label: "PP",
    priority: 10,
    icon: PpIconInactive,
    iconActive: PpIconActive,
});

app.addZone({
    key: "tracker",
    label: "Tracker",
    priority: 9,
    icon: TrackingIconInactive,
    iconActive: TrackingIconActive,
});


//Add New Models Here
const extensions: any = [
    Defaults,
    Entities,
    Users,
    Media,
    Schema,
    MainEntities,
    PowerPage,
    Tracker,
    TextToSpeech,
    KeywordRanking,
    Clients,
    Dashboard,
    Reports
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;