import React, { useMemo, useState } from 'react';
import { Col, DataTable, DataTableRow, FieldSelect, Form, InputTableCell, InputTableRow, Panel, PanelTabs, Row, Space, useForm } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import moment from 'moment';
import { get, hash, titleCase } from '@dex/bubl-helpers';
import { Change } from '../../Dashboard/Components/DashboardClientLocations';

const KeywordRankingSingleViewRankings: React.FC<any> = (props: any) => {

    const { data } = props;

    let engine = props.engine || "google";

    const [tab, setTab] = useState("organic");

    const form = useForm({ a: data.timestamps[0], b: data.timestamps[data.timestamps.length - 1] });

    const optionsA: any = [];
    const optionsB: any = [];
    const optionsLen = data.timestamps.length;

    data.timestamps.map((time, index) => {

        optionsA.push({ value: time, label: moment.unix(time).format("MMM Y") });

        if (index) optionsB.push({ value: time, label: moment.unix(time).format("MMM Y") });

    });

    if (optionsLen > 1) {
        delete (optionsA[optionsA.length - 1]);
    }

    const columns = [
        {
            label: "Keywords",
            key: "keyword",
            style: { width: 320 },
        },
        {
            label: moment.unix(form.values.a).format("MMM Y"),
            key: "a",
            style: { width: 120 },
        },
        optionsLen > 1 ? {
            label: moment.unix(form.values.b).format("MMM Y"),
            key: "b",
            style: { width: 120 },
        } : null,
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <Seperator
                heading={"Keyword Rankings"}
                tabs={[
                    { name: "organic", label: "Organic" },
                    { name: "local", label: "Local" },
                ]}
                activeTab={tab}
                setTab={setTab}
            />

            <Space height="small" />

            <Row
                gutter={8}
                edge
                justify={"end"}
            >

                <Col col={{ xs: 8, sm: 5 }} >

                    <FieldSelect
                        form={form}
                        name={"a"}
                        defaultValue={form.defaults.a}
                        required
                        options={optionsA}
                        wrapClass={"form-field-table-head"}
                        isClearable={false}
                    />

                </Col>

                {optionsLen > 1 &&
                    <Col col={{ xs: 8, sm: 5 }} >

                        <FieldSelect
                            form={form}
                            name={"b"}
                            defaultValue={form.defaults.b}
                            required
                            options={optionsB}
                            wrapClass={"form-field-table-head"}
                            isClearable={false}
                        />

                    </Col>
                }

            </Row>

            <Space height="small" />

            <DataTable
                key={hash(form.values)}
                // @ts-ignore
                columns={columns}
                data={[]}
                className={"table-head-has-fields"}
            >

                {data.keywords.map((keyword, index) => {

                    const history = get(data.history, `${keyword}.${engine}`, {});
                    const a = get(history, `${form.values.a}.${tab}`);
                    const b = get(history, `${form.values.b}.${tab}`);

                    let direction = "none";
                    let diff = b - a;

                    let title = "No Change";

                    if (diff < 0) {
                        direction = "down";
                        title = "Down " + diff;
                    } else if (diff > 0) {
                        direction = "up";
                        title = "Up " + diff;
                    }

                    return (

                        <InputTableRow>

                            <InputTableCell>
                                {titleCase(keyword)}
                            </InputTableCell>

                            <InputTableCell>
                                {a}
                                {optionsLen > 1 && a && b &&
                                    <span title={`${title}`} className={`diff-${direction}`}>
                                    </span>
                                }
                            </InputTableCell>

                            {optionsLen > 1 &&
                                <InputTableCell>
                                    {b}
                                </InputTableCell>
                            }

                        </InputTableRow>
                    )

                })}

            </DataTable>

        </>

    ), [data, tab, form]);

}

export default KeywordRankingSingleViewRankings;
