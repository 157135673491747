import React, { useCallback, useMemo } from 'react'
import { Slider } from 'antd';

import FieldWrap from '@dex/bubl-dash/src/lib/components/Fields/FieldWrap';
import { formProps } from '@dex/bubl-dash/src/lib/functions/useForm';

import './style.css';

const FieldSlider: React.FC<any> = (props: props) => {

    const { form, name } = props;

    if (form) form.addField(name, props)

    const options: any = {}

    if (props.defaultValue) options.defaultValue = props.defaultValue;
    if (props.tooltipVisible) options.tooltipVisible = props.tooltipVisible;
    if (props.tipFormatter) options.tipFormatter = props.tipFormatter;
    if (props.step) options.step = props.step;
    if (props.min) options.min = props.min;
    if (props.max) options.max = props.max;
    if (props.disabled) options.disabled = props.disabled;

    const onChange = useCallback((value) => {

        if (form) form.handleChange({ name, value })

        if (props.onChange) props.onChange(value)

    }, [])

    return useMemo(() => (

        <FieldWrap {...props}>

            <Slider
                onChange={onChange}
                {...options}
            />

        </FieldWrap>

    ), [props])

}

export default FieldSlider;

interface props {
    form: formProps;
    name: string;
    label?: string;
    required?: boolean;
    // defaultValue?: number | [number, number];
    defaultValue?: any;
    tooltipVisible?: boolean;
    tipFormatter?: boolean;
    disabled?: boolean;
    step?: number;
    min?: number;
    max?: number;
    [key: string]: any;
}