import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';

import CsvDownloader from 'react-csv-downloader'
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const ReportsSyndicationLinksTable: React.FC<any> = (props: any) => {

    const { data, url } = props;

    const [ loading, setLoading ] = useState(true);

    const parseUrl = new URL(url);

    const filename = (parseUrl.hostname + parseUrl.pathname.slice(0, -1)).replace('/', '-') + '-report';

    const csvColumn = [
        {
            id: 'link',
            displayName: "Published Link"
        }
    ];

    const columns = [
        {
            label: "Published Link",
            key: "link",
        }
    ];

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        })

    }, [])


    return useMemo(() => (

        <>

            <Panel
                heading={"Syndication Report"}
                full={false}
                actions={() => (

                    <>

                        <CsvDownloader
                            columns={csvColumn}
                            filename={filename}
                            datas={data}
                        >

                            <Button
                                label={'Download CSV'}
                                type={'faded'}
                                size={'small'}
                            />

                        </CsvDownloader>

                    </>

                )}
            >

                <Space />

                <Row>

                    <Col align={'center'} col={{ xs: 24, md: 24 }}>URL: {url}</Col>

                </Row>

                <Space />

                <DataTable
                    data={data}
                    columns={columns}
                    loading={loading}
                />

            </Panel>

        </>

    ), [props, loading]);

}

export default ReportsSyndicationLinksTable;
