
import { titleCase } from "@dex/bubl-helpers";

let settings;

export default settings = {
    key: 'textToSpeech',
    zone: 'media',
    path: '/textToSpeech',
    title: "Text To Speech",
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
};