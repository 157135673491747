import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import KeywordRankingApi from '../KeywordRankingApi';
import KeywordChange from '../../../components/KeywordChange/KeywordChange';
import KeywordRankingSingleViewKeyword from './KeywordRankingSingleViewKeyword';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import KeywordRankingSingleViewSummary from './KeywordRankingSingleViewSummary';
import PanelTabs from '@dex/bubl-dash/src/lib/components/PanelTabs/PanelTabs';
import KeywordRankingSingleViewRankings from './KeywordRankingSingleViewRakings';

const KeywordRankingSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const refresh = KeywordRankingApi.update(data.id, 'refresh');

    const handleRefresh = useCallback(() => {

        refresh.run({
            onComplete: () => {

                app.alert('Keyword Ranking Data Refreshed', 'success');

                app.navigate(nav, 'update', {});

            }
        });

    }, []);

    useEffect(() => {

        if (!data.hasFetchedOnce) handleRefresh();

    }, []);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Keyword Ranking Summary"}
                    actions={() => (

                        <>

                            <Button
                                label={'Refresh Data'}
                                type={'faded'}
                                size={'small'}
                                onClick={handleRefresh}
                                loading={refresh.loading}
                            />

                        </>

                    )}
                >

                    <KeywordRankingSingleViewSummary
                        data={data}
                    />

                    <Space />

                    <KeywordRankingSingleViewRankings
                        data={data}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data, refresh.loading]);

}

export default KeywordRankingSingleView;
