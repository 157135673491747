import React, { useMemo } from 'react';
import { FcCheckmark } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";

import styles from './TickCross.module.scss';

const TickCross: React.FC<any> = (props: props) => {

    const { value } = props;

    return useMemo(() => (

        <>

            {/* {!!value ? <FcCheckmark /> : <AiOutlineClose /> } */}
            {!!value ? <span className={styles.green}></span> : <span className={styles.red}></span> }

        </>

    ), [props])

}

export default TickCross;

interface props {
    value: boolean;
}