import React, { useEffect, useMemo } from 'react';

const SchemaArticleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const { speakable, audience, associatedMedia, faq, faqEnable, additionalType } = data;

    const { mainEntity, mainEntityOfPage, author, contentLocation, publisher, sameAs } = data.entity;

    const articleSchema = {
        "@type": data.type,
        datePublished: data.datePublished,
        dateModified: data.dateModified,
        image: data.image,
        headline: data.headline,
        backstory: data.backstory,
        "@id": data.entity['@id'],
        sameAs: sameAs,
        audience: {
            "@type": "Audience",
            ...audience
        },
        associatedMedia: associatedMedia.map(item => {

            item.type = item.type === null ? '' : item.type.charAt(0).toUpperCase() + item.type.slice(1);

            return {
                "@type": item.type,
                name: item.name,
                description: item.description,
                thumbnailUrl: item.thumbnailUrl,
                uploadDate: item.uploadDate,
                contentUrl: item.contentUrl
            }

        }),
        wordCount: data.wordcount,
        keywords: data.keywords,
        about: data.about,
        accessMode: data.accessmode,
        accessibilityFeature: data.accessibilityFeature,
        contentLocation: {
            "@type": contentLocation.type,
            address: {
                "@type": contentLocation.address.type,
                addressLocality: contentLocation.address.addressLocality,
                addressRegion: contentLocation.address.addressRegion,
                postalCode: contentLocation.address.postalCode,
                streetAddress: contentLocation.address.streetAddress
            }
        },
        publisher: {
            "@type": publisher.type,
            name: publisher.name,
            logo: {
                "@type": publisher.logo.type,
                name: publisher.logo.name,
                url: publisher.logo.url
            }
        },
        author: {
            "@type": author.type,
            name: author.name,
            email: author.email,
            telephone: author.telephone
        },
        mainEntity: {
            "@type": mainEntity.type,
            "@id": mainEntity['id'],
            additionalType: additionalType,
            url: mainEntity.url,
            name: mainEntity.name,
            image: mainEntity.image,
            priceRange: mainEntity.priceRange,
            telephone: mainEntity.telephone,
            address: {
                "@type": "PostalAddress",
                addressLocality: mainEntity.address.addressLocality,
                addressRegion: mainEntity.address.addressRegion,
                postalCode: mainEntity.address.postalCode,
                streetAddress: mainEntity.address.streetAddress
            }
        },
        mainEntityOfPage: mainEntityOfPage,
        speakable: {
            "@type": speakable.type,
            cssSelector: speakable.cssSelector
        },
        url: data.url
    }

    const faqSchema = {
        "@type": "FAQPage",
        speakable: faq.speakable,
        specialty: faq.specialty,
        accessMode: faq.accessMode,
        additionalType: faq.additionalType,
        url: faq.url,
        name: faq.name,
        alternateName: faq.alternateName,
        disambiguatingDescription: faq.disambiguatingDescription,
        image: faq.image,
        keywords: faq.keywords,
        mainEntity: faq.mainEntity.map(item => ({
            "@type": "Question",
            name: item.name,
            acceptedAnswer: {
                "@type": "Answer",
                text: item.acceptedAnswer.text,
                sameAs: item.acceptedAnswer.sameAs,
                associatedMedia: item.acceptedAnswer.associatedMedia.map((media) => ({
                    "@type": media.type,
                    name: media.name,
                    description: media.description,
                    thumbnailUrl: media.thumbnailUrl,
                    uploadDate: media.uploadDate,
                    contentUrl: media.contentUrl
                }))
            }
        }))
    }

    let schema = {
        "@context": "https://schema.org",
    }

    if (faqEnable) {

        schema["@graph"] = [{ ...articleSchema }, { ...faqSchema }];

    } else {

        schema = { ...schema, ...articleSchema }

    }

    const string = '<script type="application/ld+json">\n' + JSON.stringify(schema, null, 2) + '\n</script>';

    useEffect(() => {

        props.onLoad(string);

    }, [string, data])

    return useMemo(() => (

        <pre style={{ whiteSpace: 'pre-wrap' }}>{string}</pre>

    ), [data]);

}

export default SchemaArticleView;
