import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DeletePanel from '@dex/bubl-dash/src/lib/components/SinglePanels/DeletePanel';
import SchemaApi from '../SchemaApi';
import SchemaCreateEditForm from './SchemaCreateEditForm';

const SchemaSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const { data } = props;

    if (!Array.isArray(data.sameAs)) {

        data.sameAs = [];

    }

    const form = useForm(data);

    const update = SchemaApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Schema Saved', 'success');

            const route = { "type": "Navigation/NAVIGATE", routeName: "schemaView", params: { id: data.id } }

            app.navigate(nav, 'push', route);

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <SchemaCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />

            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>

        </Row>

    ), [data, form, update]);

}

export default SchemaSingleEdit;
