import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';

import './Home.module.scss';
import { Box, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const Home: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const [user] = app.store('currentUser');

    const zones = app.getZones(user);

    const [activeZone, setActiveZone] = app.store('activeZone');

    return useMemo(() => (

        <Box
            windowHeight={true}
            justify={"center"}
            gutter={"big"}
        >

            <div styleName="home">

                <div styleName="logo">
                    <img src="/logo.png" alt={app.options.name} />
                </div>

                <Space height={60} />

                <div styleName="zones">

                    {zones.map((zone, index) => {

                        if (zone.hidden) return (<React.Fragment key={index}></React.Fragment>);

                        return (

                            <Link styleName="zone" key={index} path={zone.path} onClick={() => { setActiveZone(zone) }}>
                                <i>
                                    {zone.icon &&
                                        <>
                                            <SVG src={zone.icon} styleName={"icon"} /><Space height={15} />
                                        </>
                                    }
                                </i>
                                <span>{zone.label}</span>
                            </Link>

                        )

                    })}

                </div>

            </div>

        </Box>


    ), [props])

}

export default Home;