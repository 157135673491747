import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';


const TrackerCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Tracker Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={"Media Entity"}
                            name='entityId'
                            isMulti={false}
                            required={true}
                            preload={true}
                            endpoint={'MainEntities'}
                            defaultValue={form.defaults.entityId}
                        />

                    </Col>
                    
                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSwitch
                            form={form}
                            label={"Green Arrow"}
                            name='greenArrow'
                            required={false}
                            defaultValue={form.defaults.greenArrow}
                        />

                    </Col>
                    
                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldDate
                            form={form}
                            label={"Start Date"}
                            name='startDate'
                            required={false}
                            defaultValue={form.defaults.startDate}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldDate
                            form={form}
                            label={"Last Update"}
                            name='lastUpdate'
                            required={false}
                            defaultValue={form.defaults.lastUpdate}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default TrackerCreateEditForm;
