import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';


const KeywordRankingCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Keyword Ranking Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            key={form.values.client}
                            form={form}
                            label={"Client"}
                            name={`clientId`}
                            required={true}
                            defaultValue={form.defaults.clientId}
                            isMulti={false}
                            preload={true}
                            endpoint={'Clients'}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Source"}
                            name={`source`}
                            required={true}
                            defaultValue={form.defaults.source}
                            options={[
                                { value: "brightLocal", label: "Bright Local" }
                            ]}
                        />

                    </Col>

                    {form.values.source &&
                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRelationship
                                key={form.values.source}
                                form={form}
                                label={"Source ID"}
                                name={`sourceId`}
                                required={true}
                                defaultValue={form.defaults.sourceId}
                                isMulti={false}
                                preload={true}
                                endpoint={'KeywordRanking/locations'}
                                where={{ "source": form.values.source }}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default KeywordRankingCreateEditForm;
