import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import TrackerApi from '../../Tracker/TrackerApi';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import TrackerSyndicationLinksTable from './ReportsSyndicationLinksTable';


const ReportsSyndication: React.FC<any> = (props: any) => {

    const [viewReport, setViewReport] = useState<any>(null);

    const form = useForm({
        uri: ''
    });

    const process = TrackerApi.create({}, "syndReport");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

            }
        });

    }, []);

    return useMemo(() => (

        <>

            {viewReport &&

                <>

                    <Modal
                        show={true}
                        onClose={setViewReport.bind(null, null)}
                        width={1180}
                    >

                        <TrackerSyndicationLinksTable data={process.data} url={form.values.uri} />

                    </Modal>

                </>

            }

            <Row gutter={'auto'}>

                <Col
                    className={'main'}
                >

                    <Panel
                        heading={"Generate Report"}
                    >

                        <Form
                            form={form}
                            onSubmit={handleSubmit}
                            loading={process.loading}
                        >

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Link To Published Article"}
                                        name='uri'
                                        required={true}
                                        defaultValue={form.defaults.uri}
                                    />

                                </Col>

                            </Row>

                            <Line />

                            <ButtonRow>

                                <Button
                                    label={"Submit"}
                                    loading={process.loading}
                                />
                                <Button
                                    label={"Reset"}
                                    type={"faded"}
                                    onClick={form.reset}
                                />

                                {process.data &&

                                    <Button
                                        label={"View Report"}
                                        type={'secondary'}
                                        onClick={setViewReport.bind(null, true)}
                                    />
                                }

                            </ButtonRow>

                        </Form>

                        {/* {process.data &&
                            <>

                                <Seperator
                                    heading={"Syndication Report"}
                                    top={"auto"}
                                    bottom={"auto"}
                                />

                                Display content from process.data and include option to download csv

                            </>
                        } */}

                    </Panel>


                </Col>

            </Row>

        </>


    ), [props, process.loading, viewReport, process.data]);

}

export default ReportsSyndication;
