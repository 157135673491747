import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';

export default function (app, settings) {

    app.addRoute({
        name: settings.key,
        zone: settings.zone,
        caps: settings.indexCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: settings.indexTitle,
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            table: {
                title: settings.title,
                getFetch: settings.indexFetch,
                preFetchFilters: settings.indexFetchParams,
                getSearchFields: settings.indexSearchFields,
                getQuickSearch: settings.indexQuickSearch,
                getColumns: settings.indexColumns
            }
        },
    });

}