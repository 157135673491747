import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';


const SchemaServiceServiceOutput: React.FC<any> = (props: any) => {

    const { form } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Service Output'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@type"}
                        name='serviceOutput.type'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@id"}
                        name='serviceOutput.id'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.id}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name='serviceOutput.name'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Brand"}
                        name='serviceOutput.brand'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.brand}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldTextArea
                        form={form}
                        label={"Description"}
                        name='serviceOutput.description'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.description}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Service Output - Offers'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@type"}
                        name='serviceOutput.offers.type'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name='serviceOutput.offers.name'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Price Currency"}
                        name='serviceOutput.offers.priceCurrency'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.priceCurrency}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldNumber
                        form={form}
                        label={"Price"}
                        name='serviceOutput.offers.price'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.price}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Availability"}
                        name='serviceOutput.offers.availability'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.availability}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldDate
                        form={form}
                        label={"Price Valid Until"}
                        name='serviceOutput.offers.priceValidUntil'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.priceValidUntil || ''}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"URL"}
                        name='serviceOutput.offers.url'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.offers.url}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Service Output - Aggregate Rating'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"@type"}
                        name='serviceOutput.aggregateRating.type'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.aggregateRating.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        label={"Rating Value"}
                        name='serviceOutput.aggregateRating.ratingValue'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.aggregateRating.ratingValue}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        label={"Review Count"}
                        name='serviceOutput.aggregateRating.reviewCount'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.aggregateRating.reviewCount}
                    />

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Service Output - Review'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"@type"}
                        name='serviceOutput.review.type'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Main Entity"}
                        name='serviceOutput.review.mainEntity'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.mainEntity}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Provider"}
                        name='serviceOutput.review.provider'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.provider}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldDate
                        form={form}
                        label={"Date Published"}
                        name='serviceOutput.review.datePublished'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.datePublished || ''}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Review Body"}
                        name='serviceOutput.review.reviewBody'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.reviewBody}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Author Type"}
                        name='serviceOutput.review.author.type'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.author.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Author Name"}
                        name='serviceOutput.review.author.name'
                        required={false}
                        defaultValue={form.defaults.serviceOutput.review.author.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <Seperator
                        top={'auto'}
                        bottom={'auto'}
                        heading={'Review Rating'}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldText
                                form={form}
                                label={"Type"}
                                name='serviceOutput.review.reviewRating.type'
                                required={false}
                                defaultValue={form.defaults.serviceOutput.review.reviewRating.type}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Best Rating"}
                                name='serviceOutput.review.reviewRating.bestRating'
                                required={false}
                                defaultValue={form.defaults.serviceOutput.review.reviewRating.bestRating}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Rating Value"}
                                name='serviceOutput.review.reviewRating.ratingValue'
                                required={false}
                                defaultValue={form.defaults.serviceOutput.review.reviewRating.ratingValue}
                            />

                        </Col>

                    </Row>

                </Col>


            </Row >

        </>


    ), [props]);

}

export default SchemaServiceServiceOutput;
