import React, { useMemo } from "react";

import Panel from "@dex/bubl-dash/src/lib/components/Panel/Panel";
import MetaTable from "@dex/bubl-dash/src/lib/components/MetaTable/MetaTable";
import TickCross from "../../../../components/TickCross/TickCross";

const TrackerTableRowView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <Panel
                heading={data.title + ' - View'}
                full={false}
            >

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            key: 'this',
                            label: 'Title',
                            format: 'ModelLink'
                        },
                        {
                            key: 'month',
                            label: 'Month',
                            format: 'titleCase'
                        },
                        {
                            key: 'datePublished',
                            label: 'Publish Date'
                        },
                        {
                            key: 'mainKeyword',
                            label: 'Main Keyword'
                        },
                        {
                            key: 'url',
                            label: 'URL'
                        }

                    ]}
                    columnB={[
                        {
                            key: 'kwds',
                            label: 'KWDS',
                            format: 'number'
                        },
                        {
                            key: 'rd',
                            label: 'RD',
                            format: 'number'
                        },
                        {
                            key: 'cta',
                            label: 'Content Frase',
                            render: (value, row) => (<TickCross value={value} />)
                        },
                        {
                            key: 'schema',
                            label: 'Schema',
                            render: (value, row) => (<TickCross value={value} />)
                        },
                        {
                            key: 'video',
                            label: "Video",
                            render: (value, row) => (<TickCross value={value} />)
                        },
                        {
                            key: 'podcast',
                            label: "Podcast",
                            render: (value, row) => (<TickCross value={value} />)
                        },
                    ]}
                    columnC={[
                        {
                            key: 'hoth',
                            label: 'Synd',
                            render: (value, row) => (<TickCross value={value} />)
                        },
                        {
                            key: 'pressADV',
                            label: 'GNews',
                            render: (value, row) => (<TickCross value={value} />)
                        },
                        {
                            key: 'guestPosts',
                            label: 'Guest Posts',
                            render: (value, row) => (<TickCross value={value} />)
                        }
                    ]}
                />

            </Panel>

        </>


    ), [props])

}

export default TrackerTableRowView;