import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import ReportSyndication from './Components/ReportsSyndication';

export default function (app, settings) {

    app.addRoute({
        name: settings.key,
        zone: settings.zone,
        caps: settings.indexCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: settings.indexTitle
            },
            beforeView: ReportSyndication
        },
    });

}