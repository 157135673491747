import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import TrackerTable from './TrackerTable/TrackerTable';
import TickCross from '../../../components/TickCross/TickCross';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';

import CsvDownloader from 'react-csv-downloader';

const TrackerSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableData, setTableData] = useState<any[]>([]);

    const csvColumn = [
        {
            displayName: 'Pub Date',
            id: 'datePublished'
        },
        {
            displayName: 'Month',
            id: 'month'
        },
        {
            displayName: 'Blog Post',
            id: 'url',
        },
        {
            displayName: "Main Keyword",
            id: 'mainKeyword',
        },
        {
            displayName: "KWDS",
            id: 'kwds',
        },
        {
            displayName: "RD",
            id: 'rd',
        },
        {
            displayName: "Video",
            id: 'video',
        },
        {
            displayName: "Podcast",
            id: 'podcast',
        },
        {
            displayName: "Schema",
            id: 'schema',
        },
        {
            displayName: "Synd",
            id: 'hoth',
        },
        {
            displayName: "GNews",
            id: 'pressADV',
        },
        {
            displayName: "Content Frase",
            id: 'cta',
        },
        {
            displayName: "Guest Posts",
            id: 'guestPosts',
        },
    ];

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                // className={'main'}
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Tracker Details"}
                    actions={() => (

                        <>
                            {!!tableData.length &&

                                <CsvDownloader
                                    columns={csvColumn}
                                    filename={data.title}
                                    datas={tableData}
                                    separator={'|'}
                                >

                                    <Button
                                        label={'Download CSV'}
                                        type={'secondary'}
                                        size={'small'}
                                    // onClick={() => { }}
                                    />

                                </CsvDownloader>

                            }

                        </>

                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                key: 'this',
                                label: 'Title',
                                format: 'ModelLink'
                            },
                            {
                                key: 'entity',
                                label: 'Main Entity',
                                format: 'ModelLink'
                            }
                        ]}
                        columnB={[
                            {
                                key: 'greenArrow',
                                label: 'Green Arrow',
                                render: (value, row) => (<TickCross value={!!value} />)
                            },
                            {
                                key: 'startDate',
                                label: 'Start Date',
                                format: 'date'
                            },
                            {
                                key: 'lastUpdate',
                                label: 'Last Update',
                                format: 'date'
                            }
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

                <Space />

                <TrackerTable data={data} tableData={(data) => setTableData(data)} />

            </Col>

            {/* <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col> */}

        </Row>

    ), [data, tableData]);

}

export default TrackerSingleView;
