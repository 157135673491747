import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const MainEntitiesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Main Entities Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: 'Title',
                                key: 'title',
                                // format: 'modellink'
                            },
                            {
                                label: 'Type',
                                key: 'type',
                            },
                            {
                                label: '@id',
                                key: '@id'
                            }
                        ]}
                        columnB={[

                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default MainEntitiesSingleView;
