import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import PowerPageApi from './PowerPageApi';

let settings;

export default settings = {
    key: 'powerPage',
    zone: 'pp',
    path: '/powerPage',
    title: "Power Page Tool",
    endpoint: PowerPageApi.endpoint,
    modelName: 'PowerPage',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewPowerPage"],
    viewCaps: ["viewPowerPage"],
    createCaps: ["createPowerPage"],
    updateCaps: ["updatePowerPage"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: PowerPageApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { fields: ['title', 'domain', 'modelName', 'created', 'id', 'noOfKeywords', 'trash'],  where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id', 'title', 'domain'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Domain",
                key: "domain",
            },
            {
                label: "# of Keywords",
                key: "noOfKeywords",
                alignEnd: true,
                format: 'number'
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: PowerPageApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: PowerPageApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { fields: { csvData: false, powerTable: false } , include: [] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};