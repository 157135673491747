import DashboardClient from "./Components/DashboardClient";

export default function (app) {

    app.addRoute({
        name: "ClientDashboard",
        zone: "none",
        path: "c/:id",
        caps: [],
        public: true,
        ribbon: true,
        sidebar: false,
        component: DashboardClient,
        componentProps: {
        },
    });

    app.addRoute({
        name: "ClientDashboardLocation",
        zone: "none",
        path: "c/:id/location/:locationId",
        caps: [],
        public: true,
        ribbon: true,
        sidebar: false,
        component: DashboardClient,
        componentProps: {
        },
    });

}