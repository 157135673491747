import { apiGet, apiPatch, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'Tracker';

const TrackerApi: any = baseApi(endpoint);

TrackerApi.getBlogPosts = (id: string, options?: options): response => {

    return apiGet({
        ...options,
        url: endpoint + '/' + id + '/blogPosts'
    })

}

export default TrackerApi;