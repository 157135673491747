import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import './Dashboard.module.scss';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';

const DashboardClientLocations: React.FC<any> = (props: any) => {

    const { client } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const handleClick = useCallback((id) => {

        const route = { "type": "Navigation/NAVIGATE", routeName: "ClientDashboardLocation", params: { id: client.id, locationId: id } }

        app.navigate(nav, 'push', route);

    }, [])

    return useMemo(() => (

        <Row
            className={"data-grid-row"}
            styleName={"card-grid"}
            gutter={'small'}
            edge={true}
        >

            {client.locations.map((location, index) => (

                <Col
                    key={index}
                    className={"data-grid-col"}
                    styleName={"card-grid-item"}
                    col={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }}
                    onClick={handleClick.bind(null, location.id)}
                >

                    <div className="body">

                        <div className="name">
                            {location.name}
                        </div>

                        <div className="change">
                            <Change
                                diff={location.changeUp - location.changeDown}
                            />
                        </div>

                    </div>

                    <div className="foot">

                        <div className="date">
                            Last Report: {moment(location.lastDate).format("MMM Y")}
                        </div>

                    </div>

                </Col>

            ))}

        </Row>

    ), [client]);

}

export const Change: React.FC<any> = (props: any) => {

    const { diff } = props;

    let direction = "none";

    if (diff < 0) direction = "down";
    else if (diff > 0) direction = "up";

    return (
        <>
            <span className={`diff-${direction}`}>
                {diff}
            </span>
        </>
    );

};

export default DashboardClientLocations;
