import React, { useCallback, useMemo } from 'react';
import { Row, Col, Box, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import useForm, { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import DashboardApi from '../DashboardApi';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';

const DashboardPass: React.FC<any> = (props: any) => {

    const { client, setPassCode } = props;

    const form = useForm({});

    const process = DashboardApi.clientValidate(client.id);

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (response) => {

                window.sessionStorage.setItem("passCode", values.passCode);

                setPassCode(values.passCode);

            }
        })

    }, [])

    return useMemo(() => (

        <Box
            windowHeight={true}
            justify={'center'}
        >

            <Row
                gutter={'auto'}
                justify={'center'}
            >

                <Col
                    col={24}
                    className={'w-420'}
                >

                    <Panel
                        heading={client.name}
                    >

                        <Line top={false} />

                        <Form
                            form={form}
                            onSubmit={handleSubmit}
                            loading={process.loading}
                        >

                            <FieldText
                                form={form}
                                label={"Pass Code"}
                                name='passCode'
                                type='password'
                                required={true}
                                defaultValue={''}
                            />

                            <Space
                                height={8}
                            />

                            <Button
                                block={true}
                                label="Submit"
                                loading={process.loading}
                            />

                        </Form>

                    </Panel>

                </Col>

            </Row>

        </Box>

    ), [client, process.loading, form]);

}

export default DashboardPass;
