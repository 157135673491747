import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const EntitiesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Entities Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "this",
                                format: 'ModelLink'
                            },
                            {
                                label: "GMB Name",
                                key: "gmbName"
                            },
                            {
                                label: "Type",
                                key: "type"
                            },
                            {
                                label: "Website",
                                key: "website"
                            },
                            {
                                label: "Phone",
                                key: "phone"
                            },
                            {
                                label: "GMB Map Url",
                                key: "gmbMapUrl"
                            },


                        ]}
                        columnB={[

                            {
                                label: "Price Range",
                                key: "priceRange"
                            },
                            {
                                label: "Image",
                                key: "imageLink"
                            },
                            {
                                label: "Logo",
                                key: "logoLink"
                            },
                            {
                                label: "City Map URL",
                                key: "cityMapUrl"
                            },
                            {
                                label: "Description",
                                key: "description"
                            },

                        ]}
                        columnC={[

                            {
                                label: "Country",
                                key: "country"
                            },
                            {
                                label: "State",
                                key: "state"
                            },
                            {
                                label: "City",
                                key: "city"
                            },
                            {
                                label: "Zip",
                                key: "zip"
                            },
                            {
                                label: "Address Line One",
                                key: "addressLineTwo"
                            },
                            {
                                label: "Address Line Two",
                                key: "addressLineTwo"
                            }

                        ]}
                    />

                    <Seperator
                        heading={"Youtube Data Gathering"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[

                            {
                                label: "Main Youtube Channel Link",
                                key: "mainYoutubeChannelLink"
                            },
                            {
                                label: "Youtube Channel Hashtag",
                                key: "youtubeChannelHashtag"
                            },
                            {
                                label: "Video Description",
                                key: "videoDescription"
                            }

                        ]}
                        columnB={[

                        ]}
                        columnC={[

                        ]}
                    />

                    <Seperator
                        heading={"Twitter Data Gathering"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[

                            {
                                label: "City Hashtag",
                                key: "cityHashtag"
                            },
                            {
                                label: "Business Type Hashtag",
                                key: "businessTypeHashtag"
                            }

                        ]}
                        columnB={[

                            {
                                label: "GMB Search String",
                                key: "gmbSearchString"
                            },
                            {
                                label: "GMB Search Tweet Link",
                                key: "gmbSearchTweetLink"
                            }

                        ]}
                        columnC={[

                            {
                                label: "City Search String",
                                key: "citySearchString"
                            },
                            {
                                label: "City Search Tweet Link",
                                key: "citySearchTweetLink"
                            }

                        ]}
                    />

                    <Seperator
                        heading={"Auto Generated & Outputs"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[

                            {
                                label: "GMB MID",
                                key: "gmbMID"
                            },
                            {
                                label: "City MID",
                                key: "cityMID"
                            },
                            {
                                label: "CID",
                                key: "cid"
                            }

                        ]}
                        columnB={[

                            {
                                label: "Latitude",
                                key: "latitude"
                            },
                            {
                                label: "Longitude",
                                key: "longitude"
                            }

                        ]}
                        columnC={[

                            {
                                label: "Youtube Video Description",
                                key: "youtubeVideoDescription"
                            }
                            
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default EntitiesSingleView;
