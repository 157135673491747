import { apiPatch, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'PowerPage';

const PowerPageApi: any = baseApi(endpoint);

PowerPageApi.filter = (id: string, options?: options):response => {

    return apiPatch({
        ...options,
        url: endpoint + '/' + id + '/filter',
        timeout: 1000000
    })

};

export default PowerPageApi;