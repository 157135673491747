import React, { useMemo, useState } from 'react';

import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';


const EntitiesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const week = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const time: any = [];

    for (let i = 0; i < 24; i++) {

        let hours = (i < 10) ? "0" + i : i;

        if (i !== 0) time.push({ value: `${hours}:00`, label: `${hours}:00` });

        time.push({ value: `${hours}:30`, label: `${hours}:30` })

    }

    // const time = [
    //     { value: "00:00", label: "00:00" },
    // ]

    return useMemo(() => (

        <Panel
            heading={"Entities Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"GMB Name"}
                                    name='gmbName'
                                    required={true}
                                    defaultValue={form.defaults.gmbName}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={false}
                                    defaultValue={form.defaults.type}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Website"}
                                    name='website'
                                    required={false}
                                    defaultValue={form.defaults.website}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Phone"}
                                    name='phone'
                                    required={false}
                                    defaultValue={form.defaults.phone}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"GMB Map Url"}
                                    name='gmbMapUrl'
                                    required={false}
                                    defaultValue={form.defaults.gmbMapUrl}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Price Range"}
                                    name='priceRange'
                                    required={false}
                                    defaultValue={form.defaults.priceRange}
                                    options={[
                                        { value: "$", label: "$" },
                                        { value: "$$", label: "$$" },
                                        { value: "$$$", label: "$$$" },
                                        { value: "$$$$", label: "$$$$" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Image"}
                                    name='imageLink'
                                    required={false}
                                    defaultValue={form.defaults.imageLink}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Logo"}
                                    name='logoLink'
                                    required={false}
                                    defaultValue={form.defaults.logoLink}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Country"}
                                    name='country'
                                    required={false}
                                    defaultValue={form.defaults.country}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"State"}
                                    name='state'
                                    required={false}
                                    defaultValue={form.defaults.state}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"City"}
                                    name='city'
                                    required={false}
                                    defaultValue={form.defaults.city}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Zip"}
                                    name='zip'
                                    required={false}
                                    defaultValue={form.defaults.zip}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Address Line One"}
                                    name='addressLineOne'
                                    required={false}
                                    defaultValue={form.defaults.addressLineOne}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Address Line Two"}
                                    name='addressLineTwo'
                                    required={false}
                                    defaultValue={form.defaults.addressLineTwo}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"City Map Url"}
                                    name='cityMapUrl'
                                    required={false}
                                    defaultValue={form.defaults.cityMapUrl}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Description"}
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Image"}
                                    name={`image`}
                                    required={false}
                                    defaultValue={form.defaults.image}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    {week.map((item) => (

                        <Col col={{ xs: 6 }}>

                            <FieldSwitch
                                form={form}
                                label={item[0].toUpperCase() + item.slice(1)}
                                name={`businessHours[${item}][active]`}
                                required={false}
                                defaultValue={form.defaults.businessHours[item].active}
                            />

                            {form.defaults.businessHours[item].active && (

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 12 }}>

                                        <FieldSelect
                                            form={form}
                                            label={"Open"}
                                            name={`businessHours[${item}][open]`}
                                            required={false}
                                            defaultValue={form.defaults.businessHours[item].open}
                                            options={time}
                                        />

                                    </Col>

                                    <Col col={{ xs: 12 }}>

                                        <FieldSelect
                                            form={form}
                                            label={"Close"}
                                            name={`businessHours[${item}][close]`}
                                            required={false}
                                            defaultValue={form.defaults.businessHours[item].close}
                                            options={time}
                                        />

                                    </Col>

                                </Row>

                            )}

                        </Col>

                    ))}

                </Row>

                <Seperator
                    heading={"Youtube Data Gathering"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Main Youtube Channel Link"}
                            name='mainYoutubeChannelLink'
                            required={false}
                            defaultValue={form.defaults.mainYoutubeChannelLink}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Youtube Channel Hashtag"}
                            name='youtubeChannelHashtag'
                            required={false}
                            defaultValue={form.defaults.youtubeChannelHashtag}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Video Description"}
                            name='videoDescription'
                            required={false}
                            defaultValue={form.defaults.videoDescription}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Twitter Data Gathering"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"City Hashtag"}
                            name='cityHashtag'
                            required={false}
                            defaultValue={form.defaults.cityHashtag}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Business Type Hashtag"}
                            name='businessTypeHashtag'
                            required={false}
                            defaultValue={form.defaults.businessTypeHashtag}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"GMB Search String"}
                            name='gmbSearchString'
                            required={false}
                            defaultValue={form.defaults.gmbSearchString}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"GMB Search Tweet Link"}
                            name='gmbSearchTweetLink'
                            required={false}
                            defaultValue={form.defaults.gmbSearchTweetLink}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"City Search String"}
                            name='citySearchString'
                            required={false}
                            defaultValue={form.defaults.citySearchString}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"City Search Tweet Link"}
                            name='citySearchTweetLink'
                            required={false}
                            defaultValue={form.defaults.citySearchTweetLink}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Auto Generated & Outputs"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"GMB MID"}
                            name='gmbMID'
                            required={false}
                            defaultValue={form.defaults.gmbMID}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"City MID"}
                            name='cityMID'
                            required={false}
                            defaultValue={form.defaults.cityMID}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"CID"}
                            name='cid'
                            required={false}
                            defaultValue={form.defaults.cid}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Latitude"}
                            name='latitude'
                            required={false}
                            defaultValue={form.defaults.latitude}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Longitude"}
                            name='longitude'
                            required={false}
                            defaultValue={form.defaults.longitude}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Youtube Video Description"}
                            name='youtubeVideoDescription'
                            required={false}
                            defaultValue={form.defaults.youtubeVideoDescription}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Cities"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRepeater
                            form={form}
                            name={'cities'}
                            addButtonLabel={"Add City"}
                        >

                            {form.values.cities.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Name"}
                                                name={`cities[${n}][citySectionName]`}
                                                required={false}
                                                defaultValue={row.citySectionName}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Map URL"}
                                                name={`cities[${n}][citySectionMapUrl]`}
                                                required={false}
                                                defaultValue={row.citySectionMapUrl}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Map Embed Code"}
                                                name={`cities[${n}][mapEmbedCode]`}
                                                required={false}
                                                defaultValue={row.mapEmbedCode}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"City to GMB Driving Directions"}
                                                name={`cities[${n}][cityToGmbDrivingDirections]`}
                                                required={false}
                                                defaultValue={row.cityToGmbDrivingDirections}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Street View"}
                                                name={`cities[${n}][citySectionStreetView]`}
                                                required={false}
                                                defaultValue={row.citySectionStreetView}
                                            />

                                        </Col>

                                    </Row>

                                    <Seperator
                                        heading={"Google Earth Data Gathering"}
                                        top={"auto"}
                                        bottom={"auto"}
                                    />

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Google Earth Link"}
                                                name={`cities[${n}][citySectionGoogleEarthLink]`}
                                                required={false}
                                                defaultValue={row.citySectionGoogleEarthLink}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Google Earth Presentation Link"}
                                                name={`cities[${n}][citySectionGoogleEarthPresentationLink]`}
                                                required={false}
                                                defaultValue={row.citySectionGoogleEarthPresentationLink}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Google Earth Tweet"}
                                                name={`cities[${n}][citySectionGoogleEarthTweet]`}
                                                required={false}
                                                defaultValue={row.citySectionGoogleEarthTweet}
                                            />

                                        </Col>

                                    </Row>

                                    <Seperator
                                        heading={"Youtube Data Gathering"}
                                        top={"auto"}
                                        bottom={"auto"}
                                    />

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Youtube Channel Link"}
                                                name={`cities[${n}][citySectionYoutubeChannelLink]`}
                                                required={false}
                                                defaultValue={row.citySectionYoutubeChannelLink}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Youtube Channel Tweet"}
                                                name={`cities[${n}][citySectionYoutubeChannelTweet]`}
                                                required={false}
                                                defaultValue={row.citySectionYoutubeChannelTweet}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section Playlist"}
                                                name={`cities[${n}][citySectionPlaylist]`}
                                                required={false}
                                                defaultValue={row.citySectionPlaylist}
                                            />

                                        </Col>

                                    </Row>

                                    <Seperator
                                        heading={"Twitter Data Gathering"}
                                        top={"auto"}
                                        bottom={"auto"}
                                    />

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"GMB Search String"}
                                                name={`cities[${n}][gmbSearchString]`}
                                                required={false}
                                                defaultValue={row.gmbSearchString}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"GMB Search Tweet"}
                                                name={`cities[${n}][gmbSearchTweet]`}
                                                required={false}
                                                defaultValue={row.gmbSearchTweet}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Search String"}
                                                name={`cities[${n}][citySearchString]`}
                                                required={false}
                                                defaultValue={row.citySearchString}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Search Tweet"}
                                                name={`cities[${n}][citySearchTweet]`}
                                                required={false}
                                                defaultValue={row.citySearchTweet}
                                            />

                                        </Col>

                                    </Row>

                                    <Seperator
                                        heading={"Auto Generated"}
                                        top={"auto"}
                                        bottom={"auto"}
                                    />

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"City Section MID"}
                                                name={`cities[${n}][citySectionMID]`}
                                                required={false}
                                                defaultValue={row.citySectionMID}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"Latitude"}
                                                name={`cities[${n}][latitude]`}
                                                required={false}
                                                defaultValue={row.latitude}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label={"Longitude"}
                                                name={`cities[${n}][longitude]`}
                                                required={false}
                                                defaultValue={row.longitude}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                label={"Generated Comment"}
                                                name={`cities[${n}][generatedComment]`}
                                                required={false}
                                                defaultValue={row.generatedComment}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                label={"Youtube Video Description"}
                                                name={`cities[${n}][youtubeVideoDescription]`}
                                                required={false}
                                                defaultValue={row.youtubeVideoDescription}
                                            />

                                        </Col>

                                    </Row>

                                    <Seperator
                                        heading={"Hoods"}
                                        top={"auto"}
                                        bottom={"auto"}
                                    />

                                    <FieldRepeater
                                        form={form}
                                        name={'cities[hoods]'}
                                        addButtonLabel={"Add Hood"}
                                    >

                                        {Array.isArray(form.values.cities.hoods) && form.values.cities.hoods.map((row, n) => (

                                            <React.Fragment key={form.key + "-" + n}>

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood Name"}
                                                            name={`cities[hoods][${n}][hoodName]`}
                                                            required={false}
                                                            defaultValue={row.hoodName}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood Map URL"}
                                                            name={`cities[hoods][${n}][hoodMapUrl]`}
                                                            required={false}
                                                            defaultValue={row.hoodMapUrl}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Map Embed Code"}
                                                            name={`cities[hoods][${n}][mapEmbedCode]`}
                                                            required={false}
                                                            defaultValue={row.mapEmbedCode}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood to GMB Driving Directions"}
                                                            name={`cities[hoods][${n}][hoodToGmbDrivingDirections]`}
                                                            required={false}
                                                            defaultValue={row.hoodToGmbDrivingDirections}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Street View URL"}
                                                            name={`cities[hoods][${n}][streetViewURL]`}
                                                            required={false}
                                                            defaultValue={row.streetViewURL}
                                                        />

                                                    </Col>

                                                </Row>

                                                <Seperator
                                                    heading={"Google Earth Data Gathering"}
                                                    top={"auto"}
                                                    bottom={"auto"}
                                                />

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood Google Earth Link"}
                                                            name={`cities[hoods][${n}][hoodGoogleEarthLink]`}
                                                            required={false}
                                                            defaultValue={row.hoodGoogleEarthLink}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood Google Earth Presentation Link"}
                                                            name={`cities[hoods][${n}][hoodGoogleEarthPresentationLink]`}
                                                            required={false}
                                                            defaultValue={row.hoodGoogleEarthPresentationLink}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood Google Earth Tweet"}
                                                            name={`cities[hoods][${n}][hoodGoogleEarthTweet]`}
                                                            required={false}
                                                            defaultValue={row.hoodGoogleEarthTweet}
                                                        />

                                                    </Col>

                                                </Row>

                                                <Seperator
                                                    heading={"Twitter Data Gathering"}
                                                    top={"auto"}
                                                    bottom={"auto"}
                                                />

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"GMB Search String"}
                                                            name={`cities[hoods][${n}][gmbSearchString]`}
                                                            required={false}
                                                            defaultValue={row.gmbSearchString}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"GMB Search Tweet"}
                                                            name={`cities[hoods][${n}][gmbSearchTweet]`}
                                                            required={false}
                                                            defaultValue={row.gmbSearchTweet}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"City Search String"}
                                                            name={`cities[hoods][${n}][citySearchString]`}
                                                            required={false}
                                                            defaultValue={row.citySearchString}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"City Search Tweet"}
                                                            name={`cities[hoods][${n}][citySearchTweet]`}
                                                            required={false}
                                                            defaultValue={row.citySearchTweet}
                                                        />

                                                    </Col>

                                                </Row>

                                                <Seperator
                                                    heading={"Auto Generated"}
                                                    top={"auto"}
                                                    bottom={"auto"}
                                                />

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Hood MID"}
                                                            name={`cities[hoods][${n}][hoodMID]`}
                                                            required={false}
                                                            defaultValue={row.hoodMID}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Latitude"}
                                                            name={`cities[hoods][${n}][latitude]`}
                                                            required={false}
                                                            defaultValue={row.latitude}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Longitude"}
                                                            name={`cities[hoods][${n}][longitude]`}
                                                            required={false}
                                                            defaultValue={row.longitude}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 24 }}>

                                                        <FieldTextArea
                                                            form={form}
                                                            label={"Youtube Video Description"}
                                                            name={`cities[hoods][${n}][youtubeVideoDescription]`}
                                                            required={false}
                                                            defaultValue={row.youtubeVideoDescription}
                                                        />

                                                    </Col>

                                                </Row>

                                            </React.Fragment>

                                        ))}

                                    </FieldRepeater>

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default EntitiesCreateEditForm;
