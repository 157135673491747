import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

import SchemaArticleForm from './SchemaArticle/SchemaArticleForm';
import SchemaLoacalBusinessForm from './SchemaLocalBusiness/SchemaLocalBusinessForm';
import SchemaServiceForm from './SchemaService/SchemaServiceForm';

const SchemaCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Schema Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Status"}
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "draft", label: "Draft" },
                                { value: "approved", label: "Approved" },
                                { value: "published", label: "Published" },
                                { value: "publishedTracked", label: "Published & Tracked" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldSelect
                            form={form}
                            label={"Type"}
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "Article", label: "Article" },
                                { value: "NewsArticle", label: "News Article" },
                                { value: "BlogPosting", label: "BlogPosting" },
                                { value: "LocalBusiness", label: "Local Business" },
                                { value: "Service", label: "Service" },
                            ]}
                            disabled={typeof form.defaults.type !== 'undefined'}
                        />

                    </Col>

                </Row>

                {form.values.type && <Line />}

                {form.values.type === 'LocalBusiness' && <SchemaLoacalBusinessForm form={form} />}
                
                {form.values.type === 'Service' && <SchemaServiceForm form={form} />}

                {!['LocalBusiness', 'Service'].includes(form.values.type) && form.values.type && <SchemaArticleForm form={form} />}

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default SchemaCreateEditForm;
