import React, { useCallback, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Alert from '@dex/bubl-dash/src/lib/components/Alert/Alert';
import Button, { ButtonGroup, ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';

const ClientsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    console.log(app);

    const link = `${window.location.protocol}//${window.location.host}/c/${data.id}`;
    const copy = `Link: ${link}\nPass Code: ${data.passCode}`;

    const copyToClipboard = useCallback(() => {

        navigator.clipboard.writeText(copy);

        app.alert("Copied to clipboard");

    }, []);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Clients Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: 'Name',
                                key: 'name',
                            }
                        ]}
                        columnB={[
                            {
                                label: 'Pass Code',
                                key: 'passCode',
                            }
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

                <Space />

                <Alert
                    message={"Share the following details with the client to grant them access to their dashboard."}
                    description={<>

                        <MetaTable
                            data={{ ...data, link: link }}
                            columnA={[
                                {
                                    label: 'Link',
                                    key: 'link',
                                }
                            ]}
                            columnB={[
                                {
                                    label: 'Pass Code',
                                    key: 'passCode',
                                }
                            ]}
                            columnSize={{ xs: 24 }}
                        />
                        <Line />
                        <ButtonRow>
                            <Button
                                label="Copy to Clipboard"
                                onClick={copyToClipboard}
                            />
                            <Button
                                label="View Dashboard"
                                type={"faded"}
                                path={link}
                                target={"_blank"}
                            />
                        </ButtonRow>
                    </>}
                />

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default ClientsSingleView;
