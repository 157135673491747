import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import SchemaCreateEditForm from './Components/SchemaCreateEditForm';
import SchemaApi from './SchemaApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: SchemaCreateEditForm,
                getCreate: SchemaApi.create,
                getClone: SchemaApi.getOne,
                defaults: {
                    dateModified: Date.now(),
                    datePublished: Date.now(),
                    accessmode: [],
                    keywords: [],
                    associatedMedia: [],
                    accessibilityFeature: [],
                    speakable: {
                        cssSelector: []
                    },
                    areaServed: [],
                    sameAs: [],
                    additionalType: [],
                    aggregateRating: {},
                    address: {},
                    geo: {},
                    availableChannel: {
                        servicePhone: {}
                    },
                    serviceOutput: {
                        offers: {},
                        aggregateRating: {},
                        review: {
                            author:{},
                            reviewRating:{},
                        },
                    },
                    image: [],
                    provider: {},
                    faq: {
                        accessmode: [],
                        additionalType: [],
                        keywords: [],
                        mainEntity: [],
                    }
                },
                successMessage: "Schema Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}