import PowerPageIndex from './PowerPageIndex';
import PowerPageCreate from './PowerPageCreate';
import PowerPageSettings from './PowerPageSettings';
import PowerPageSingle from './PowerPageSingle';

export default function (app) {

    const settings = PowerPageSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    PowerPageIndex(app, settings);

    PowerPageCreate(app, settings);

    PowerPageSingle(app, settings);

}