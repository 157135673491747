import React, { useEffect, useMemo, useState } from 'react';
import { get } from '@dex/bubl-helpers';
import DashboardPass from './DashboardPass';
import DashboardApi from '../DashboardApi';
import PageHeader from '@dex/bubl-dash/src/lib/components/PageHeader/PageHeader';
import DashboardClientLocations from './DashboardClientLocations';
import { Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DashboardClientSingleLocation from './DashboardClientSingleLocation';

const DashboardClient: React.FC<any> = (props: any) => {

    const id = get(props, 'routeProps.routeParams.id');
    const locationId = get(props, 'routeProps.routeParams.locationId');

    const [client, setClient] = useState<any>();

    const [passCode, setPassCode] = useState(window.sessionStorage.getItem("passCode"));

    const getClient = DashboardApi.client(id);

    useEffect(() => {

        getClient.run({
            params: { passCode: passCode },
            onComplete: (response) => {

                setClient(response);

            }
        })

    }, [id, passCode]);

    return useMemo(() => (

        <>

            {client &&
                <>

                    {!client.validated &&
                        <DashboardPass
                            client={client}
                            setPassCode={setPassCode}
                        />
                    }

                    {client.validated &&

                        <>

                            <PageHeader
                                title={client.name}
                                buttons={[
                                    !locationId ? null : {
                                        path: `/c/${client.id}`,
                                        type: 'primary',
                                        label: "View Locations"
                                    }
                                ].filter(Boolean)}
                            />

                            <Box gutter={"auto"}>

                                {locationId &&
                                    <DashboardClientSingleLocation
                                        client={client}
                                        passCode={passCode}
                                        locationId={locationId}
                                    />
                                }

                                {!locationId &&
                                    <DashboardClientLocations
                                        client={client}
                                    />
                                }

                            </Box>

                        </>
                    }

                </>
            }

        </>

    ), [client, locationId, passCode]);

}

export default DashboardClient;
