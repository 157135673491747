import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash';
import KeywordChange from '../../../components/KeywordChange/KeywordChange';

const KeywordRankingSingleViewSummary: React.FC<any> = (props: any) => {

    const { data } = props;

    let diff: any = data.changeUp - data.changeDown;

    let direction = "none";

    if (diff < 0) direction = "down";
    else if (diff > 0) direction = "up";

    if (diff < 0) diff = "-" + diff;
    else if (diff > 0) diff = "+" + diff;

    return useMemo(() => (


        <Row justify='center' gutter={8} edge>

            <Col col={{ xs: 12, sm: 8 }}>

                <KeywordChange
                    type={"up"}
                    value={data.changeUp}
                    label={"Spots Moved Up"}
                />

            </Col>

            <Col col={{ xs: 12, sm: 8 }}>

                <KeywordChange
                    type={"down"}
                    value={data.changeDown}
                    label={"Spots Moved Down"}
                />

            </Col>

            <Col col={{ xs: 24, sm: 8 }}>

                <KeywordChange
                    type={direction}
                    value={diff}
                    label={"Overall Change"}
                />

            </Col>

        </Row>

    ), [data]);

}

export default KeywordRankingSingleViewSummary;
