import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import SchemaArticleFaq from './Sections/SchemaArticleFaq';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';


const SchemaArticleForm: React.FC<any> = (props: any) => {

    const { form } = props;

    if (!form.values.audience) {

        form.values['audience'] = {
            audienceType: []
        }

    }

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldDate
                        form={form}
                        label={"Date Published"}
                        name='datePublished'
                        required={false}
                        defaultValue={form.defaults.datePublished || ""}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldDate
                        form={form}
                        label={"Date Modified"}
                        name='dateModified'
                        required={false}
                        defaultValue={form.defaults.dateModified || ""}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Image"}
                        name='image'
                        required={false}
                        defaultValue={form.defaults.image}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Headline"}
                        name='headline'
                        required={false}
                        defaultValue={form.defaults.headline}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Backstory"}
                        name='backstory'
                        required={false}
                        defaultValue={form.defaults.backstory}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"About"}
                        name='about'
                        required={false}
                        defaultValue={form.defaults.about}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"URL"}
                        name='url'
                        required={false}
                        defaultValue={form.defaults.url}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Wordcount"}
                        name='wordcount'
                        required={false}
                        defaultValue={form.defaults.wordcount}
                    />

                </Col>

                {form.values.type !== 'LocalBusiness' &&

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldRelationship
                            form={form}
                            label={"Main Entity"}
                            name={`entityId`}
                            required={true}
                            preload={true}
                            endpoint={'MainEntities'}
                            defaultValue={form.defaults.entityId}
                        />

                    </Col>

                }

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Additional Type'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'additionalType'}
                        addButtonLabel={"Add Additional Type"}
                    >

                        {form.values.additionalType.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Additional Type"}
                                    name={`additionalType[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Audience'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'audience.audienceType'}
                        addButtonLabel={"Add Audience Type"}
                    >

                        {Array.isArray(form.values.audience.audienceType) && form.values.audience.audienceType.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Audience Type"}
                                    name={`audience.audienceType[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={'Associated Media'}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'associatedMedia'}
                        addButtonLabel={"Add Associated Media"}
                    >

                        {form.values.associatedMedia.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 24 }} >

                                        <FieldText
                                            form={form}
                                            label={"Name"}
                                            name={`associatedMedia[${n}][name]`}
                                            required={false}
                                            defaultValue={row.name}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }} >

                                        <FieldSelect
                                            form={form}
                                            label={"Type"}
                                            name={`associatedMedia[${n}][type]`}
                                            required={true}
                                            defaultValue={row.type}
                                            options={[
                                                { value: "audioObject", label: "Audio" },
                                                { value: "videoObject", label: "Video" }
                                            ]}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }} >

                                        <FieldDate
                                            form={form}
                                            label={"Upload Date"}
                                            name={`associatedMedia[${n}][uploadDate]`}
                                            required={false}
                                            defaultValue={row.uploadDate}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }} >

                                        <FieldText
                                            form={form}
                                            label={"Thumbnail URL"}
                                            name={`associatedMedia[${n}][thumbnailUrl]`}
                                            required={false}
                                            defaultValue={row.thumbnailUrl}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }} >

                                        <FieldText
                                            form={form}
                                            label={"Content URL"}
                                            name={`associatedMedia[${n}][contentUrl]`}
                                            required={false}
                                            defaultValue={row.contentUrl}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 24 }} >

                                        <FieldTextArea
                                            form={form}
                                            label={"Description"}
                                            name={`associatedMedia[${n}][description]`}
                                            required={false}
                                            defaultValue={row.description}
                                        />

                                    </Col>

                                </Row>


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={"Keywords"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'keywords'}
                        addButtonLabel={"Add Keyword"}
                    >

                        {form.values.keywords.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Keywords"}
                                    name={`keywords[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={"Accessmode"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'accessmode'}
                        addButtonLabel={"Add Accessmode"}
                    >

                        {form.values.accessmode.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Accessmode"}
                                    name={`accessmode[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={"Accessibility Feature"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'accessibilityFeature'}
                        addButtonLabel={"Add Accessibility Feature"}
                    >

                        {form.values.accessibilityFeature.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Accessibility Feature"}
                                    name={`accessibilityFeature[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={"Speakable"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Type"}
                        name={`speakable.type`}
                        required={false}
                        defaultValue={form.defaults.speakable.type}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'speakable.cssSelector'}
                        addButtonLabel={"Add CSS Selector"}
                    >

                        {Array.isArray(form.values.speakable.cssSelector) && form.values.speakable.cssSelector.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"CSS Selector"}
                                    name={`speakable.cssSelector[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

            </Row>

            <Seperator
                top={'auto'}
                bottom={'auto'}
                heading={"FAQ"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldSwitch
                        form={form}
                        label="Enable FAQ"
                        name="faqEnable"
                        required={false}
                        defaultValue={form.defaults.faqEnable}
                    />

                </Col>

            </Row>

            {form.values.faqEnable && <SchemaArticleFaq form={form} />}

        </>


    ), [props]);

}

export default SchemaArticleForm;
