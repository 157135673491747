
import TextToSpeechSettings from './TextToSpeechSettings';
import TextToSpeechIndex from './TextToSpeechIndex';

export default function (app) {

    const settings = TextToSpeechSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        // modelName: settings.modelName,
        // endpoint: settings.endpoint,
        // idKey: settings.idKey,
        // primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "Content Creator",
                path: settings.path,
            }
        ],
    });

    TextToSpeechIndex(app, settings);

}