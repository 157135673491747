import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';


const ClientsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    useEffect(() => {

        if (form.values.passCode) return;

        const digit = function () {
            return "" + Math.floor(Math.random() * 9);
        }

        const code = digit() + digit() + digit() + digit() + digit();

        form.reset({ ...form.defaults, passCode: code });

        form.setKey();

    }, []);

    return useMemo(() => (

        <Panel
            heading={"Clients Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name={`name`}
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldText
                            form={form}
                            label={"Pass Code"}
                            name={`passCode`}
                            required={true}
                            defaultValue={form.defaults.passCode}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ClientsCreateEditForm;
