import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';


const MainEntitiesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Main Entities Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Type"}
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "Organization", label: "Organization" },
                                { value: "Person", label: "Person" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"@id"}
                            name='@id'
                            required={false}
                            defaultValue={form.defaults['@id']}
                        />

                    </Col>

                </Row>

                <Seperator
                    top={'auto'}
                    bottom={'auto'}
                    heading={'Same As'}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldRepeater
                            form={form}
                            name={'sameAs'}
                            addButtonLabel={"Add Same As"}
                        >

                            {form.values.sameAs.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <FieldText
                                        form={form}
                                        label={"Same As"}
                                        name={`sameAs[${n}]`}
                                        required={false}
                                        defaultValue={typeof (row) === "object" ? "" : row }
                                    />


                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </Col>

                </Row>

                <Seperator
                    top={'auto'}
                    bottom={'auto'}
                    heading={"Content Location"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label={"Type"}
                            name={`contentLocation.type`}
                            required={false}
                            defaultValue={form.defaults.contentLocation.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Address Type"}
                            name={`contentLocation.address.type`}
                            required={false}
                            defaultValue={form.defaults.contentLocation.address.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Address Locality"}
                            name={`contentLocation.address.addressLocality`}
                            required={false}
                            defaultValue={form.defaults.contentLocation.address.addressLocality}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Address Region"}
                            name={`contentLocation.address.addressRegion`}
                            required={false}
                            defaultValue={form.defaults.contentLocation.address.addressRegion}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Postal Code"}
                            name={`contentLocation.address.postalCode`}
                            required={false}
                            defaultValue={form.defaults.contentLocation.address.postalCode}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label={"Street Address"}
                            name={`contentLocation.address.streetAddress`}
                            required={false}
                            defaultValue={form.defaults.contentLocation.address.streetAddress}
                        />

                    </Col>

                </Row>

                <Seperator
                    top={'auto'}
                    bottom={'auto'}
                    heading={"Publisher"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Type"}
                            name={`publisher.type`}
                            required={false}
                            defaultValue={form.defaults.publisher.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name={`publisher.name`}
                            required={false}
                            defaultValue={form.defaults.publisher.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Logo Type"}
                            name={`publisher.logo.type`}
                            required={false}
                            defaultValue={form.defaults.publisher.logo.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Logo Name"}
                            name={`publisher.logo.name`}
                            required={false}
                            defaultValue={form.defaults.publisher.logo.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Logo URL"}
                            name={`publisher.logo.url`}
                            required={false}
                            defaultValue={form.defaults.publisher.logo.url}
                        />

                    </Col>

                </Row>

                <Seperator
                    top={'auto'}
                    bottom={'auto'}
                    heading={"Author"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Type"}
                            name={`author.type`}
                            required={false}
                            defaultValue={form.defaults.author.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name={`author.name`}
                            required={false}
                            defaultValue={form.defaults.author.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Email"}
                            name={`author.email`}
                            required={false}
                            defaultValue={form.defaults.author.email}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Telephone"}
                            name={`author.telephone`}
                            required={false}
                            defaultValue={form.defaults.author.telephone}
                        />

                    </Col>

                </Row>

                <Seperator
                    top={'auto'}
                    bottom={'auto'}
                    heading={"Main Entity"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Type"}
                            name={`mainEntity.type`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"ID"}
                            name={`mainEntity.id`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.id}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"URL"}
                            name={`mainEntity.url`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.url}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name={`mainEntity.name`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Image"}
                            name={`mainEntity.image`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.image}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Price Range"}
                            name={`mainEntity.priceRange`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.priceRange}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Telephone"}
                            name={`mainEntity.telephone`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.telephone}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Address Type"}
                            name={`mainEntity.address.type`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.address.type}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Address Locality"}
                            name={`mainEntity.address.addressLocality`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.address.addressLocality}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Address Region"}
                            name={`mainEntity.address.addressRegion`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.address.addressRegion}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Postal Code"}
                            name={`mainEntity.address.postalCode`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.address.postalCode}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Street Address"}
                            name={`mainEntity.address.streetAddress`}
                            required={false}
                            defaultValue={form.defaults.mainEntity.address.streetAddress}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label={"Main Entity Of Page"}
                            name={`mainEntityOfPage`}
                            required={false}
                            defaultValue={form.defaults.mainEntityOfPage}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default MainEntitiesCreateEditForm;
