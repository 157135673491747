import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ReportsApi from './ReportsApi';

let settings;

export default settings = {
    key: 'reports',
    zone: 'tracker',
    path: '/reports',
    title: "Syndication",
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return 'Syndication Report';

    }
};