import React from 'react';

import styles from "./KeywordChange.module.scss";

const KeywordChange: React.FC<KeywordChangeProps> = (props) => {

    const { type, label, value } = props;

    return (

        <>

            <div className={[styles.item, styles[type]].join(" ")}>

                <div className={styles.value}>
                    {value}
                </div>

                <div className={styles.label}>
                    {label}
                </div>

            </div>

        </>

    )

}

interface KeywordChangeProps {
    [key: string]: any,
}

export default KeywordChange;