import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import SchemaApi from './SchemaApi';

let settings;

export default settings = {
    key: 'schema',
    zone: 'sg',
    path: '/schema',
    title: "Schema Generator",
    endpoint: SchemaApi.endpoint,
    modelName: 'Schema',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewSchema"],
    viewCaps: ["viewSchema"],
    createCaps: ["createSchema"],
    updateCaps: ["updateSchema"],
    actions: ['trash', 'clone'],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // },
            {
                label: "Draft",
                path: settings.path + "/status/draft",
                caps: settings.indexCaps
            },
            {
                label: "Approved",
                path: settings.path + "/status/approved",
                caps: settings.indexCaps
            },
            {
                label: "Published",
                path: settings.path + "/status/published",
                caps: settings.indexCaps
            },
            {
                label: "Tracked",
                path: settings.path + "/status/publishedTracked",
                caps: settings.indexCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: SchemaApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['entity'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id', 'title'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Main Entity",
                key: "entityId",
                type: "relationship",
                model: "MainEntities"
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "draft", label: "Draft" },
                    { value: "approved", label: "Approved" },
                    { value: "published", label: "Published" },
                    { value: "publishedTracked", label: "Published & Tracked" },
                ]
            },
            {
                label: "Type",
                key: "type",
                type: "choice",
                options: [
                    { value: "Article", label: "Article" },
                    { value: "NewsArticle", label: "News Article" },
                    { value: "BlogPosting", label: "BlogPosting" },
                    { value: "LocalBusiness", label: "Local Business" },
                ]
            }
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Type",
                key: "type"
            },
            {
                label: "Main Entity",
                key: "entity",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                render: (value, row) => {

                    if (value === 'publishedTracked') value = 'Plublished & Tracked'

                    return titleCase(value);

                }
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: SchemaApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: SchemaApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['entity'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};