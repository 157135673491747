import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Col, Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldSlider from '../../../components/FieldSlider/FieldSlider';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

import VoiceList from '../Voice.json';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';

const ToolPage: React.FC<props> = (props) => {

    const app = useApp();

    const key: any = process.env.REACT_APP_AZURE_KEY;
    const region: any = process.env.REACT_APP_AZURE_REGION;

    const [voice, setVoice] = useState<any[]>([]);

    const [voiceStyle, setVoiceStyle] = useState<any[]>([{ label: 'General', value: 'general' }]);

    const player = useRef<any>(null);

    const [playing, setPlaying] = useState(false);

    const [downloading, setDownloading] = useState(false);

    const textRef = useRef<HTMLTextAreaElement>(null);

    const form = useForm({
        filename: 'Speak IT',
        language: 'en-US',
        voice: '',
        voiceStyle: 'general',
        speakingSpeed: 1,
        pitch: 1,
        // text: 'Hello, how are you'
        text: 'You can replace this text with any text you wish.'
        // text: ''
    });

    const onSubmit = useCallback((values) => {

        console.log(values)

    }, [])

    const genSSML = () => {

        let speakingSpeed = 0;

        let pitch = 0;

        speakingSpeed = (form.values.speakingSpeed - 1) * 100;
        speakingSpeed = Math.round(speakingSpeed);

        pitch = (form.values.pitch - 1) * 50;
        pitch = Math.round(pitch);

        let xml = '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="' + form.values.language + '">';

        xml += '<voice name="' + form.values.voice + '">';

        if (form.values.voiceStyle !== 'general') xml += '<mstts:express-as style="' + form.values.voiceStyle + '">';

        xml += '<prosody rate="' + speakingSpeed + '%" pitch="' + pitch + '%">' + form.values.text + '</prosody>';

        if (form.values.voiceStyle !== 'general') xml += '</mstts:express-as>';

        xml += '</voice>';

        xml += '</speak>';

        return xml;

    }

    const tagInsert = (tag: string) => {

        const pos = textRef.current?.selectionStart;

        if (typeof (pos) === 'number') {

            const textValue: string = textRef.current!.value;

            let newValue: any = textValue.split('');

            newValue.splice(pos, 0, tag);

            newValue = newValue.join('');

            textRef.current!.value = newValue;

            textRef.current!.selectionStart = pos + textValue.length;

            textRef.current!.focus();

            form.handleChange({ name: 'text', value: newValue })

        }

    }

    const playAudio = () => {

        setPlaying(true);

        const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);

        player.current = new sdk.SpeakerAudioDestination();

        player.current.onAudioEnd = (sender) => {

            setPlaying(false);

        }

        const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player.current);

        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

        const ssml = genSSML();

        synthesizer.speakSsmlAsync(ssml,
            result => {

                const audioData = result.audioData;

                synthesizer.close();

            },
            error => {

                console.log(error);

                synthesizer.close();

                setPlaying(false);

            }
        );


    }

    const stopAudio = () => {

        player.current.pause();

        player.current.close();

        setPlaying(false);

    }

    const downloadAudio = () => {

        setDownloading(true);

        const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);

        speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio24Khz160KBitRateMonoMp3;

        const stream = sdk.AudioOutputStream.createPullStream();

        const audioConfig = sdk.AudioConfig.fromStreamOutput(stream);

        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

        const ssml = genSSML();

        synthesizer.speakSsmlAsync(ssml,
            result => {

                const audioData = result.audioData;

                const blob = new Blob([audioData], { type: 'audio/mp3' })

                const url = window.URL.createObjectURL(blob);

                const audioDownload = document.createElement('a');

                audioDownload.href = url;

                audioDownload.download = form.values.filename + '.mp3';

                audioDownload.click();

                audioDownload.remove();

                synthesizer.close();

                setDownloading(false);

            },
            error => {

                console.log(error);

                app.alert('Download Failed', 'error');

                synthesizer.close();

                setDownloading(false);

            }
        );


    }


    //Update Voice Dropdown List
    useEffect(() => {

        const language = form.values.language;

        const voiceList = VoiceList.filter(item => item.Locale === language).map(item => {

            let label = item.DisplayName;
            if (item.VoiceType !== 'Standard') label += ` (${item.VoiceType})`;

            return {
                label: label,
                value: item.ShortName
            }

        })

        setVoice(voiceList);

    }, [form.values.language])

    //Update Voice Style Dropdown List
    useEffect(() => {

        const selectedVoice = form.values.voice;

        const voiceStyle: any = [
            { label: 'General', value: 'general' }
        ]

        const voice = VoiceList.filter(item => item.ShortName === selectedVoice)[0];

        if (voice && Array.isArray(voice.StyleList)) {

            const styleList = voice.StyleList.map(item => ({ label: item.charAt(0).toUpperCase() + item.slice(1), value: item }));

            voiceStyle.push(...styleList);

        }

        setVoiceStyle(voiceStyle)

    }, [form.values.voice])

    return useMemo(() => (

        <Box gutter={"auto"}>

            <Panel
                heading={'Content'}
            >

                <Form
                    form={form}
                    onSubmit={onSubmit}
                >

                    <Row gutter={40} edge={true}>

                        <Col col={{ xs: 24, md: 16 }} className={'text-to-speech'}>

                            <FieldTextArea
                                forwardedRef={textRef}
                                form={form}
                                name={'text'}
                                required={false}
                                defaultValue={form.defaults.text}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={'File Name'}
                                        name={'filename'}
                                        required={true}
                                        defaultValue={form.defaults.filename}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldSelect
                                        form={form}
                                        label={'Language'}
                                        name={'language'}
                                        required={true}
                                        defaultValue={form.defaults.language}
                                        options={[

                                            { label: 'English (US)', value: 'en-US' }

                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldSelect
                                        form={form}
                                        label={'Voice'}
                                        name={'voice'}
                                        required={true}
                                        defaultValue={form.defaults.voice}
                                        options={voice}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldSelect
                                        form={form}
                                        label={'Voice Style'}
                                        name={'voiceStyle'}
                                        required={true}
                                        defaultValue={form.defaults.voiceStyle}
                                        options={voiceStyle}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldSlider
                                        form={form}
                                        label={`Speaking Speed (${form.values.speakingSpeed})`}
                                        name={'speakingSpeed'}
                                        step={0.01}
                                        min={0}
                                        max={3}
                                        defaultValue={form.defaults.speakingSpeed}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldSlider
                                        form={form}
                                        label={`Pitch (${form.values.pitch})`}
                                        name={'pitch'}
                                        step={0.01}
                                        min={0}
                                        max={2}
                                        defaultValue={form.defaults.pitch}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </Form>

                <Space />

                <Row gutter={40} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <ButtonRow>

                            <Button
                                label={'Word'}
                                type={'primary'}
                                size={'small'}
                                onClick={() => tagInsert('<word>')}
                            />

                        </ButtonRow>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <ButtonRow>

                            {!playing ?

                                <Button
                                    label={'Play'}
                                    type={'primary'}
                                    size={'medium'}
                                    loading={false}
                                    onClick={playAudio}
                                />

                                :

                                <Button
                                    label={'Stop'}
                                    type={'primary'}
                                    size={'medium'}
                                    loading={false}
                                    onClick={stopAudio}
                                />

                            }

                            <Button
                                label={'Download'}
                                type={'secondary'}
                                size={'medium'}
                                loading={downloading}
                                onClick={downloadAudio}
                            />

                        </ButtonRow>

                    </Col>

                </Row>

            </Panel>

        </Box>

    ), [props, form, setVoice, setVoiceStyle]);

}

interface props {
    [key: string]: any
}

export default ToolPage;
