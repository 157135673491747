import React, { useCallback, useMemo } from "react";

import Panel from "@dex/bubl-dash/src/lib/components/Panel/Panel";
import { Form, useForm } from "@dex/bubl-dash/src/lib/functions/useForm";
import { Col, Row } from "@dex/bubl-dash/src/lib/components/Layout/Layout";
import FieldText from "@dex/bubl-dash/src/lib/components/Fields/FieldText";
import Line from "@dex/bubl-dash/src/lib/components/Line/Line";
import Button, { ButtonRow } from "@dex/bubl-dash/src/lib/components/Button/Button";
import FieldSelect from "@dex/bubl-dash/src/lib/components/Fields/FieldSelect";
import FieldNumber from "@dex/bubl-dash/src/lib/components/Fields/FieldNumber";
import FieldSwitch from "@dex/bubl-dash/src/lib/components/Fields/FieldSwitch";
import TrackerApi from "../../TrackerApi";
import { useApp } from "@dex/bubl-dash/src/lib/functions/useApp";

const TrackerTableRowEditForm: React.FC<any> = (props: any) => {

    const { data, tracker } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const process = TrackerApi.update(tracker.id, 'updateBlogPost');

    const form = useForm({
        mainKeyword: '',
        month: '',
        rd: 0,
        bl: 0,
        hoth: false,
        pressADV: false,
        greenArrow: false,
        guestPosts: false,
        cta: false,
        ...tracker.schema[data.id] || {}
    });

    const onSubmit = useCallback((values) => {

        process.run({
            data: { id: data.id, ...values },
            onComplete: (data) => {

                app.alert('Details updated', 'success');

                app.navigate(nav, 'update', {});

            }

        })

    }, [form])

    return useMemo(() => (

        <>

            <Panel
                heading={data.title + ' - Edit'}
                full={false}
            >

                <Form
                    form={form}
                    loading={process.loading}
                    onSubmit={onSubmit}
                >

                    <Row gutter={8} edge={true}>

                        {/* <Col col={{ xs: 24, md: 8 }}>

                            <FieldSelect
                                form={form}
                                label={'Blog Type'}
                                name={'blogType'}
                                required={false}
                                defaultValue={form.defaults.blogType}
                                options={[
                                    { label: 'Brand', value: 'Brand' },
                                    { label: 'Location', value: 'Location' },
                                    { label: 'SEO', value: 'SEO' },
                                ]}
                            />

                        </Col> */}

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldText
                                form={form}
                                label={'Main Keyword'}
                                name={'mainKeyword'}
                                required={false}
                                defaultValue={form.defaults.mainKeyword}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldSelect
                                form={form}
                                label={'Month'}
                                name={'month'}
                                required={false}
                                defaultValue={form.defaults.month}
                                options={[
                                    { label: 'January', value: 'January' },
                                    { label: 'February', value: 'February' },
                                    { label: 'March', value: 'March' },
                                    { label: 'April', value: 'April' },
                                    { label: 'May', value: 'May' },
                                    { label: 'June', value: 'June' },
                                    { label: 'July', value: 'July' },
                                    { label: 'August', value: 'August' },
                                    { label: 'September', value: 'September' },
                                    { label: 'October', value: 'October' },
                                    { label: 'November', value: 'November' },
                                    { label: 'December', value: 'December' }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldNumber
                                form={form}
                                label={'KWDS'}
                                name={'kwds'}
                                required={false}
                                defaultValue={form.defaults.kwds}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldNumber
                                form={form}
                                label={'RD'}
                                name={'rd'}
                                required={false}
                                defaultValue={form.defaults.rd}
                            />

                        </Col>

                        {/* <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={'BL'}
                                name={'bl'}
                                required={false}
                                defaultValue={form.defaults.bl}
                            />

                        </Col> */}

                        <Col col={{ xs: 12, md: 6 }}>

                            <FieldSwitch
                                form={form}
                                label={'C.Fra'}
                                name={'cta'}
                                required={false}
                                defaultValue={form.defaults.cta}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }}>

                            <FieldSwitch
                                form={form}
                                label={'Synd'}
                                name={'hoth'}
                                required={false}
                                defaultValue={form.defaults.hoth}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }}>

                            <FieldSwitch
                                form={form}
                                label={'GNews'}
                                name={'pressADV'}
                                required={false}
                                defaultValue={form.defaults.pressADV}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 6 }}>

                            <FieldSwitch
                                form={form}
                                label={'Guest Posts'}
                                name={'guestPosts'}
                                required={false}
                                defaultValue={form.defaults.guestPosts}
                            />

                        </Col>

                    </Row>

                    <Line />

                    <ButtonRow>

                        <Button
                            label={'Submit'}
                            loading={process.loading}
                        />

                    </ButtonRow>

                </Form>

            </Panel>

        </>


    ), [props, form])

}

export default TrackerTableRowEditForm;