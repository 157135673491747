import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import React, { useMemo } from 'react';


const SchemaArticleFaq: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Speakable"}
                        name={`faq.speakable`}
                        required={false}
                        defaultValue={form.defaults.faq.speakable}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Specialty"}
                        name={`faq.specialty`}
                        required={false}
                        defaultValue={form.defaults.faq.specialty}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"URL"}
                        name={`faq.url`}
                        required={false}
                        defaultValue={form.defaults.faq.url}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Name"}
                        name={`faq.name`}
                        required={false}
                        defaultValue={form.defaults.faq.name}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Alternate Name"}
                        name={`faq.alternateName`}
                        required={false}
                        defaultValue={form.defaults.faq.alternateName}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Image"}
                        name={`faq.image`}
                        required={false}
                        defaultValue={form.defaults.faq.image}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Disambiguating Description"}
                        name={`faq.disambiguatingDescription`}
                        required={false}
                        defaultValue={form.defaults.faq.disambiguatingDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'faq.accessmode'}
                        addButtonLabel={"Add Accessmode"}
                    >

                        {form.values.faq.accessmode.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Accessmode"}
                                    name={`faq.accessmode[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'faq.additionalType'}
                        addButtonLabel={"Add Additional Type"}
                    >

                        {form.values.faq.additionalType.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Additional Type"}
                                    name={`faq.additionalType[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'faq.keywords'}
                        addButtonLabel={"Add Keywords"}
                    >

                        {form.values.faq.keywords.map((row, n) => (

                            <React.Fragment key={form.key + "-" + n}>

                                <FieldText
                                    form={form}
                                    label={"Keywords"}
                                    name={`faq.keywords[${n}]`}
                                    required={false}
                                    defaultValue={typeof (row) === "object" ? "" : row}
                                />


                            </React.Fragment>

                        ))}

                    </FieldRepeater>

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldRepeater
                        form={form}
                        name={'faq.mainEntity'}
                        addButtonLabel={"Add FAQ"}
                    >

                        {form.values.faq.mainEntity.map((row, n) => {

                            if (!row.acceptedAnswer) {

                                row.acceptedAnswer = {
                                    sameAs: [],
                                    associatedMedia: []
                                };

                            }

                            return (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row edge={true} gutter={8}>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Question"}
                                                name={`faq.mainEntity[${n}].name`}
                                                required={false}
                                                defaultValue={row.name}
                                            />

                                        </Col>

                                    </Row>

                                    <Seperator
                                        heading={'Accepted Answer'}
                                        top={'auto'}
                                        bottom={'auto'}
                                    />

                                    <Row edge={true} gutter={8}>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <Row gutter={8} edge={true}>

                                                <Col col={{ xs: 24, md: 24 }}>

                                                    <FieldText
                                                        form={form}
                                                        label={"Answer"}
                                                        name={`faq.mainEntity[${n}].acceptedAnswer.text`}
                                                        required={false}
                                                        defaultValue={row.acceptedAnswer.text}
                                                    />

                                                </Col>

                                                <Col col={{ xs: 24, md: 24 }}>

                                                    <FieldRepeater
                                                        form={form}
                                                        name={`faq.mainEntity[${n}].acceptedAnswer.sameAs`}
                                                        addButtonLabel={"Add Same As"}
                                                    >

                                                        {form.values.faq.mainEntity[n].acceptedAnswer.sameAs.map((row, index) => (

                                                            <React.Fragment key={form.key + "-" + index}>

                                                                <FieldText
                                                                    form={form}
                                                                    label={"Same As"}
                                                                    name={`faq.mainEntity[${n}].acceptedAnswer.sameAs[${index}]`}
                                                                    required={false}
                                                                    defaultValue={typeof (row) === "object" ? "" : row}
                                                                />


                                                            </React.Fragment>

                                                        ))}

                                                    </FieldRepeater>

                                                </Col>

                                                <Col col={{ xs: 24, md: 24 }}>

                                                    <FieldRepeater
                                                        form={form}
                                                        name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia`}
                                                        addButtonLabel={"Add Associated Media"}
                                                    >

                                                        {form.values.faq.mainEntity[n].acceptedAnswer.associatedMedia.map((row, index) => (

                                                            <React.Fragment key={form.key + "-" + index}>

                                                                <Row gutter={8} edge={true}>

                                                                    <Col col={{ xs: 24, md: 12 }}>

                                                                        <FieldSelect
                                                                            form={form}
                                                                            label={"Type"}
                                                                            name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia[${index}].type`}
                                                                            required={false}
                                                                            defaultValue={row.type}
                                                                            options={[
                                                                                { label: 'Video', value: 'VideoObject' },
                                                                                { label: 'Audio', value: 'AudioObject' }
                                                                            ]}
                                                                        />

                                                                    </Col>

                                                                    <Col col={{ xs: 24, md: 12 }}>

                                                                        <FieldText
                                                                            form={form}
                                                                            label={"Name"}
                                                                            name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia[${index}].name`}
                                                                            required={false}
                                                                            defaultValue={row.name}
                                                                        />

                                                                    </Col>

                                                                    <Col col={{ xs: 24, md: 12 }}>

                                                                        <FieldDate
                                                                            form={form}
                                                                            label={"Upload Date"}
                                                                            name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia[${index}].uploadDate`}
                                                                            required={false}
                                                                            defaultValue={row.uploadDate || ""}
                                                                        />

                                                                    </Col>

                                                                    <Col col={{ xs: 24, md: 12 }}>

                                                                        <FieldText
                                                                            form={form}
                                                                            label={"Content Url"}
                                                                            name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia[${index}].contentUrl`}
                                                                            required={false}
                                                                            defaultValue={row.contentUrl}
                                                                        />

                                                                    </Col>

                                                                    <Col col={{ xs: 24, md: 24 }}>

                                                                        <FieldText
                                                                            form={form}
                                                                            label={"Thumbnail Url"}
                                                                            name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia[${index}].thumbnailUrl`}
                                                                            required={false}
                                                                            defaultValue={row.thumbnailUrl}
                                                                        />

                                                                    </Col>

                                                                    <Col col={{ xs: 24, md: 24 }}>

                                                                        <FieldTextArea
                                                                            form={form}
                                                                            label={"Description"}
                                                                            name={`faq.mainEntity[${n}].acceptedAnswer.associatedMedia[${index}].description`}
                                                                            required={false}
                                                                            defaultValue={row.description}
                                                                        />

                                                                    </Col>

                                                                </Row>

                                                            </React.Fragment>

                                                        ))}

                                                    </FieldRepeater>

                                                </Col>

                                            </Row>

                                        </Col>

                                    </Row>

                                </React.Fragment>

                            )

                        })}

                    </FieldRepeater>

                </Col>

            </Row>

        </>

    ), [props])

}

export default SchemaArticleFaq;