import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import MainEntitiesCreateEditForm from './Components/MainEntitiesCreateEditForm';
import MainEntitiesApi from './MainEntitiesApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: MainEntitiesCreateEditForm,
                getCreate: MainEntitiesApi.create,
                getClone: MainEntitiesApi.getOne,
                defaults: {
                    sameAs: [],
                    contentLocation: {
                        address: {}
                    },
                    publisher: {
                        logo: {}
                    },
                    author: {},
                    mainEntity: {
                        address: {}
                    }
                },
                successMessage: "Main Entities Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}