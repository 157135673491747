import { apiGet, apiPost, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'Dashboard';

const DashboardApi: any = baseApi(endpoint);

DashboardApi.client = (id: string, options?: options): response => {

    return apiGet({
        ...options,
        url: 'Clients/public/' + id
    });

};

DashboardApi.clientValidate = (id: string, options?: options): response => {

    return apiPost({
        ...options,
        url: 'Clients/public/' + id + '/validate'
    });

};

DashboardApi.clientLocation = (id: string, locationId: string, options?: options): response => {

    return apiGet({
        ...options,
        url: 'Clients/public/' + id + '/location/' + locationId
    });

};

export default DashboardApi;