import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Col, Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import PowerPageApi from '../../PowerPageApi';
import PowerPageKeywordsTable from './PowerPageKeywordsTable';

import './PowerPageTable.module.scss';
import CsvDownloader from 'react-csv-downloader';
import Loading from '@dex/bubl-dash/src/lib/components/Loading/Loading';

const PowerPagePdfTemplate = React.lazy(() => import('../PowerPagePdf/PowerPagePdfTemplate'));

const PowerPageTable: React.FC<any> = (props: any) => {

    const { data } = props;

    const [viewKeywords, setViewKeywords] = useState<any>(null);

    const [viewPdf, setViewPdf] = useState<any>(null);

    const [powerData, setPowerData] = useState<any[]>([]);

    const process = PowerPageApi.filter(data.id, {
        onComplete: async (values) => {

            setPowerData(values || []);

        }
    });

    const csvColumn = [
        {
            id: 'url',
            displayName: "Power Page URL"
        },
        {
            id: 'keywordsCount',
            displayName: "Keywords on the SERP"
        },
        {
            id: 'pageIndex',
            displayName: "Power Page Index"
        }
    ];

    const columns = [
        {
            label: "Power Page URL",
            key: "url",
            style: { width: 20 },
            render: (value, row) => (

                <div styleName='urlWrap'>

                    <div styleName="url">{value}</div>

                    {" "}

                    <div styleName={'btnKeywords'}>

                        <Button
                            label={'Keywords'}
                            type="faded"
                            size={'small'}
                            onClick={setViewKeywords.bind(null, { url: value, keywords: row.keywords })}
                        />

                    </div>


                </div>

            )
        },
        {
            label: "Keywords on the SERP",
            key: "keywordsCount",
            alignEnd: true,
            format: 'number'
        },
        {
            label: "Power Page Index",
            key: "pageIndex",
            format: "number",
            alignEnd: true
        }
    ];

    const form = useForm({
        positionHigh: 1,
        positionLow: 100,
        searchVolumeHigh: 1000000,
        searchVolumeLow: 10,
        maxUrls: 50,
        urlStringMatch: ''
    });

    const onSubmit = useCallback((values) => {

        process.run({ data: values })

    }, [form])

    useEffect(() => {

        process.run({ data: form.values });

    }, [])

    return useMemo(() => (

        <>

            {viewKeywords &&

                <>

                    <Modal
                        show={true}
                        onClose={setViewKeywords.bind(null, null)}
                        width={1180}
                    >

                        <PowerPageKeywordsTable data={viewKeywords.keywords} url={viewKeywords.url} />

                    </Modal>

                </>

            }

            {viewPdf &&

                <>

                    <Modal
                        show={true}
                        onClose={setViewPdf.bind(null, null)}
                        width={1180}
                    >

                        <Suspense fallback={<Loading />}>

                            <PowerPagePdfTemplate title={data.title} data={powerData || []} />

                        </Suspense>

                    </Modal>

                </>

            }

            <Panel
                heading={"Power Page Table"}
                full={false}
            >

                <Form
                    form={form}
                    onSubmit={onSubmit}
                    loading={process.loading}
                >

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 5 }}>

                            <FieldText
                                form={form}
                                label={"Position High"}
                                name='positionHigh'
                                required={false}
                                defaultValue={form.defaults.positionHigh}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 5 }}>

                            <FieldText
                                form={form}
                                label={"Position Low"}
                                name='positionLow'
                                required={false}
                                defaultValue={form.defaults.positionLow}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 5 }}>

                            <FieldText
                                form={form}
                                label={"Search Volume High"}
                                name='searchVolumeHigh'
                                required={false}
                                defaultValue={form.defaults.searchVolumeHigh}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 5 }}>

                            <FieldText
                                form={form}
                                label={"Search Volume Low"}
                                name='searchVolumeLow'
                                required={false}
                                defaultValue={form.defaults.searchVolumeLow}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 4 }}>

                            <FieldText
                                form={form}
                                label={"Max URLs"}
                                name='maxUrls'
                                required={false}
                                defaultValue={form.defaults.maxUrls}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldText
                                form={form}
                                label={"URL String Match"}
                                name='urlStringMatch'
                                required={false}
                                defaultValue={form.defaults.urlStringMatch}
                            />

                        </Col>

                    </Row>

                    <Line />

                    <ButtonRow>

                        <Button
                            label={"Search"}
                            loading={process.loading}
                        />

                        <Button
                            label={'Download PDF'}
                            type={'secondary'}
                            onClick={setViewPdf.bind(null, true)}
                        />

                        <CsvDownloader
                            columns={csvColumn}
                            filename={data.title}
                            datas={powerData}
                        >

                            <Button
                                label={'Download CSV'}
                                type={'secondary'}
                                onClick={() => { }}
                            />

                        </CsvDownloader>

                    </ButtonRow>

                </Form>

            </Panel>

            <Space />

            <Panel
                full={true}
            >

                <DataTable
                    data={powerData}
                    columns={columns}
                    loading={process.loading}
                />

            </Panel>

        </>

    ), [data, viewKeywords, powerData, process]);

}

export default PowerPageTable;
