import ClientsIndex from './ClientsIndex';
import ClientsCreate from './ClientsCreate';
import ClientsSettings from './ClientsSettings';
import ClientsSingle from './ClientsSingle';

export default function (app) {

    const settings = ClientsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    ClientsIndex(app, settings);

    ClientsCreate(app, settings);

    ClientsSingle(app, settings);

}