import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import React, { useCallback, useMemo } from 'react';
import { AiOutlineCopy } from 'react-icons/ai'
import SchemaArticleView from './SchemaArticle/SchemaArticleView';
import SchemaLocalBusinessView from './SchemaLocalBusiness/SchemaLocalBusinessView';
import SchemaServiceView from './SchemaService/SchemaServiceView';

const SchemaJsonView: React.FC<any> = (props: any) => {

    const app = useApp();

    let string = '';

    const { data } = props;

    const onClick = useCallback(() => {

        const textField = document.createElement('textarea');

        textField.innerText = string;

        document.body.appendChild(textField);

        textField.select();

        document.execCommand('copy');

        textField.remove();

        app.alert('Copied!', 'success');

    }, [string])

    const onLoad = (values) => {

        string = values

    }

    return useMemo(() => (

        <Panel
            heading={"Generated JSON"}
            actions={(

                <>

                    <Button
                        size={'small'}
                        type={'primary'}
                        icon={<AiOutlineCopy />}
                        onClick={onClick}
                    />

                </>

            )}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }} >

                    {data.type === 'LocalBusiness' && <SchemaLocalBusinessView data={data} onLoad={onLoad} />}

                    {data.type === 'Service' && <SchemaServiceView data={data} onLoad={onLoad} />}

                    {!['LocalBusiness', 'Service'].includes(data.type) && <SchemaArticleView data={data} onLoad={onLoad} />}

                </Col>

            </Row>

        </Panel>

    ), [data]);

}

export default SchemaJsonView;
