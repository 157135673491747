
import React, { useEffect, useMemo, useState } from 'react'
import TrackerTableRowEditForm from './TrackerTableRowEditForm';
import TrackerTableRowView from './TrackerTableRowView';

import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import TickCross from '../../../../components/TickCross/TickCross';
import { FcGenealogy, FcList, FcEditImage, FcDeleteDatabase } from 'react-icons/fc';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';
import TrackerApi from '../../TrackerApi';

import './TrackerTable.module.scss';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import SchemaApi from '../../../Schema/SchemaApi';

const TrackerTable: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [tableData, setTableData] = useState<any[]>([]);

    const [editRow, setEditRow] = useState<any>(null);

    const [viewRow, setViewRow] = useState<any>(null);

    const [order, setOrder] = useState<string[]>(['datePublished DESC']);

    const blogPosts = TrackerApi.getBlogPosts(data.id);

    const schemaUpdate = SchemaApi.update();

    const setFilters = (filters) => {

        setOrder(filters.order)

    }

    const removeBlogPost = (id) => {

        schemaUpdate.run({
            url: "Schema/" + id + '/update',
            data: { status: 'published' },
            onComplete: () => {

                app.alert('Schema Untracked', 'success');

            }
        });

    }

    useEffect(() => {

        blogPosts.run({
            params: {
                order: order,
                limit: 0
            },
            onComplete: (values) => {

                setTableData(values)

                props.tableData(values)

            }
        })

    }, [order, schemaUpdate.loading])

    const columns = [
        {
            label: 'Pub Date',
            key: 'datePublished',
            sort: true
        },
        {
            label: 'Blog Post',
            key: 'url',
            sort: true,
            render: (value, row) => (
                <div styleName={'blogColumn'}>
                    <a rel="noreferrer" target="_blank" href={value}>{value}</a>
                </div>
            )
        },
        {
            label: "KWDS",
            key: 'kwds',
            format: 'number',
            sort: true
        },
        {
            label: "RD",
            key: 'rd',
            format: 'number',
            sort: true
        },
        {
            label: "C.Fra",
            key: 'cta',
            alignMiddle: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: "Sch.",
            key: 'schema',
            alignMiddle: true,
            sort: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: "Vid",
            key: 'video',
            alignMiddle: true,
            sort: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: "PodC",
            key: 'podcast',
            alignMiddle: true,
            sort: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: "Synd",
            key: 'hoth',
            alignMiddle: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: "GNews",
            key: 'pressADV',
            alignMiddle: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: 'Guest Posts',
            key: 'guestPosts',
            alignMiddle: true,
            render: (value, row) => (<TickCross value={value} />)
        },
        {
            label: "Actions",
            key: '',
            alignMiddle: true,
            style: { minWidth: '120px' },
            render: (value, row) => (

                <ButtonRow>
                    <Button
                        type={'inline'}
                        size={'medium'}
                        icon={<FcList />}
                        onClick={setViewRow.bind(null, row)}
                    />

                    <Button
                        type={'inline'}
                        size={'medium'}
                        icon={<FcEditImage />}
                        onClick={setEditRow.bind(null, {
                            ...row,
                            ...data.schema[row.id]
                        })}
                    />

                    <Button
                        type={'inline'}
                        size={'medium'}
                        icon={<FcDeleteDatabase />}
                        confirm={{
                            title: 'Deleting Tracker Data',
                            message: 'Are you sure ?'
                        }}
                        onClick={() => removeBlogPost(row.id)}
                    />

                    <Link path={'/schema/' + row.id + '/view'} >
                        <Button
                            type={'inline'}
                            size={'medium'}
                            icon={<FcGenealogy />}
                        />
                    </Link>

                </ButtonRow>

            )
        },
    ]

    return useMemo(() => (

        <>

            {editRow && (

                <Modal
                    show={true}
                    onClose={setEditRow.bind(null, null)}
                    width={1180}
                >

                    <TrackerTableRowEditForm
                        tracker={data}
                        data={editRow}
                    />

                </Modal>

            )}

            {viewRow && (

                <Modal
                    show={true}
                    onClose={setViewRow.bind(null, null)}
                    width={1180}
                >

                    <TrackerTableRowView
                        data={viewRow}
                    />

                </Modal>

            )}

            <Panel
                full={true}
            >

                <DataTable
                    data={tableData}
                    columns={columns}
                    loading={blogPosts.loading}
                    setFilters={setFilters}
                    order={order}
                />

            </Panel>

        </>

    ), [props, blogPosts, tableData, order]);

}

export default TrackerTable;

interface props {
    data: object,
    tableData?: (data: any[]) => void;
}