import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import PowerPageTable from './PowerPageTables/PowerPageTable';

const PowerPageSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Power Page Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                key: 'this',
                                label: 'Title',
                                format: 'modellink'
                            }
                        ]}
                        columnB={[
                            {
                                key: 'domain',
                                label: 'Domain'
                            }
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

                {!data.trash &&

                    <>

                        <Space />

                        <PowerPageTable data={data} />

                    </>

                }

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default PowerPageSingleView;
